/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */

import "./GetKeywordsTable.scss";

import {
  Box,
  Card,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

const GetKeywordsTable = ({ apiResponse, tab }: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const filterOptions = [
    { key: "all", value: "All" },
    { key: "semrush", value: "Semrush" },
    { key: "google_suggest", value: "Google Suggest" },
    { key: "gsc", value: "GSC" },
    { key: "gbp", value: "GBP" },
  ];

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (option: string) => {
    if (option === "All") {
      setSelectedFilters([]);
    } else {
      setSelectedFilters((prev) =>
        prev.includes(option)
          ? prev.filter((item) => item !== option)
          : [...prev, option]
      );
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
    setPage(0);
  };
  let filteredData: any = [];
  if (tab === 1) {
    filteredData = apiResponse.filter((row: any) => {
      const matchesSearch =
        searchTerm === "" || row.keyword.toLowerCase().includes(searchTerm);
      const matchesFilter =
        selectedFilters.length === 0 || selectedFilters.includes(row.source);
      return matchesSearch && matchesFilter;
    });
  } else if (tab === 2) {
    filteredData = apiResponse.filter((val:any)=> val !== "").filter((val:any)=> val.toLowerCase().includes(searchTerm));
  }

  return (
    <Card className="secondtab-card-container">
      <Box className="card-header">
        <Typography variant="h5">Keywords</Typography>
        {tab === 1 && (
          <Typography
            variant="body2"
            sx={{
              color: "var(--text-secondary, rgba(0, 0, 0, 0.60))",
              opacity: 0.6,
              fontFeatureSettings: "'liga' off, 'clig' off",
            }}
          >
            {filteredData.length} total keywords
          </Typography>
        )}
        {tab === 2 && (
          <Typography
            variant="body2"
            sx={{
              color: "var(--text-secondary, rgba(0, 0, 0, 0.60))",
              opacity: 0.6,
              fontFeatureSettings: "'liga' off, 'clig' off",
            }}
          >
            {filteredData.length} total keywords
          </Typography>
        )}
      </Box>

      <Box className="search-container">
        <Box className="search-filter">
          <TextField
            fullWidth
            label="Search Keywords"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search keywords..."
          />
          {tab === 1 && (
            <IconButton onClick={handleFilterClick}>
              <FilterAltIcon />
            </IconButton>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleFilterClose}
          >
            {filterOptions.map(({ key, value }) => (
              <MenuItem key={key} onClick={() => handleFilterChange(value)}>
                <Checkbox
                  checked={
                    (selectedFilters.length === 0 && key === "all") ||
                    selectedFilters.includes(value)
                  }
                />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>

      {/* Table */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>KEYWORDS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{padding:"10px !important", borderBottom:"1px solid rgba(0, 0, 0, 0.06) !important"}} >{tab === 1 ? row.keyword : row}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100,200,300,400,500,1000]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Card>
  );
};

export default GetKeywordsTable;
