import { Navigate, useLocation } from 'react-router-dom'

import React from 'react'
import { useSelector } from 'react-redux';

const UnProtectedRoutes = ({ children }: any) => {
  const { userDetails, isAuthenticated } = useSelector((state: any) => state.auth);
  const location = useLocation().pathname;

  if (isAuthenticated === null || isAuthenticated === undefined || parseInt(isAuthenticated) === 0) {
    return children;
  }
  if (userDetails && userDetails.roleid === 2) {
    return <Navigate to="/settings" state={{ from: location }} replace />;
  }

  return <Navigate to="/home" state={{ from: location }} replace />;
};

export default UnProtectedRoutes;
