/* eslint-disable react-hooks/exhaustive-deps */

import "./Stepper.scss";

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";

interface StepperProps {
  data: string[];
  nextStepCsv: (value: string) => void;
  csvFile: string
}

const Stepper: React.FC<StepperProps> = ({ data, nextStepCsv, csvFile }) => {
  const [value, setValue] = useState<string>(csvFile || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value) {
        nextStepCsv(value);
    }
  }, [value]);

  return (
    <Box className="stepper-container">
      <Box className="inner-stepper-container">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            className="stepper-radio-group"
          >
            {Array.isArray(data) && data.length > 0
              ? data.map((step, index) => (
                  <FormControlLabel
                    key={index}
                    value={step}
                    control={<Radio />}
                    label={step}
                    className="stepper-item"
                  />
                ))
              : "No steps available"}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Stepper;

