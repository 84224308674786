/* eslint-disable react-hooks/exhaustive-deps */

import "./ReviewEmailEditScreen.scss";

import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AlertCustomDialog from "../../components/Dialog/AlertCustomDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomToast from "../../utils/cutomToast/CustomToast";
import PhoneFrameOuter from "../../components/svg/PhoneFrameOuter";
import RefreshIcon from "@mui/icons-material/Refresh";
import phoneImg from "../../assets/images/Gold.png";
import { useSelector } from "react-redux";

const ReviewSMSEditScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { doApi } = useApi();
  const { activity, userUniqueId, action, campaignName, activites } =
    location.state;
  const [data, setData] = useState<any>(activity);
  const [subjectLine, setSubjectLine] = useState(
    data.Mode.toUpperCase() === "SMS"
      ? data?.SMSInfo?.Text
      : data?.EmailInfo?.Subject
  );
  const [reviewLinkUrl, setReviewLinkUrl] = useState(data?.Link?.Url);
  const profileData = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  );
  const [mode, setMode] = useState(data.Mode.toUpperCase());
  const [content, setContent] = useState(data?.EmailInfo?.Content);
  const [buttonText, setButtonText] = useState(data?.EmailInfo?.SendText);
  const [currentIndex, setCurrentIndex] = useState(
    activites.findIndex((item: any) => item.UniqueId === data.UniqueId)
  );

  const handleNavigation = (direction: "prev" | "next") => {
    const newIndex = direction === "prev" ? currentIndex - 1 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    if (currentIndex >= 0 && currentIndex < activites.length) {
      const currentActivity = activites[currentIndex];
      setData(currentActivity);
      const modeActivity = currentActivity?.Mode?.toUpperCase();
      setMode(currentActivity?.Mode?.toUpperCase());
      setReviewLinkUrl(currentActivity?.Link?.Url);
      setSubjectLine(
        modeActivity === "SMS"
          ? currentActivity?.SMSInfo?.Text
          : currentActivity?.EmailInfo?.Subject
      );
      if (modeActivity === "EMAIL") {
        setContent(currentActivity?.EmailInfo?.Content);
        setButtonText(currentActivity?.EmailInfo?.SendText);
      }
    }
  }, [currentIndex]);

  const [htmlContent, setHtmlContent] = useState("");
  const reviewLinkOption = data?.Link?.Type;
  const selectedDays = data?.Schedule?.SendOnDays || [];

  const mailSendingOption = data?.SMSInfo?.Frequency || "anytime";

  useEffect(() => {
    let generatedHtml = "";
    if (mode === "SMS") {
      generatedHtml = `${subjectLine} \n \n ${
        reviewLinkUrl ? reviewLinkUrl : "Link"
      }`;
    } else {
      generatedHtml = `\n
    <div style="font-family: Arial, sans-serif; line-height: 1.5;">\n
      <h1 style="font-size: 18px; color: #333; text-align: left;">\n
        ${profileData?.Name}\n
      </h1>\n
      <p style="font-size: 16px; color: #555; white-space: pre-wrap; margin: 20px 0;">\n
        ${content.trim()}\n
      </p>\n
      <div style="text-align: left;">\n
        <a href="${reviewLinkUrl}" style="display: inline-block; padding: 10px 20px; background-color: #0F4343; color: #fff; text-decoration: none; border-radius: 4px;">\n
          ${buttonText}\n
        </a>\n
      </div>\n
    </div>\n`;
    }

    setHtmlContent(generatedHtml);
  }, [subjectLine, content, buttonText, reviewLinkUrl, profileData?.Name]);

  const transformTimeSlots = (sendAtTime: any) => {
    return (
      sendAtTime?.map((slot: any) => ({
        id: Date.now(),
        start: slot.StartTime,
        end: slot.EndTime,
      })) || [{ id: Date.now(), start: "10:00", end: "12:00" }]
    );
  };

  const timeSlots = transformTimeSlots(data?.Schedule?.SendAtTime);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [type, setType] = useState("Default");

  useEffect(() => {
    if (hasUnsavedChanges) {
      setType("Custom");
    }
  }, [hasUnsavedChanges]);

  const [dialogState, setDialogState] = useState<{
    alertDialog: boolean;
  }>({
    alertDialog: false,
  });

  // Function to toggle dialogs
  const toggleDialog = (dialogName: keyof typeof dialogState) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialogName]: !prevState[dialogName],
    }));
  };

  const handleExitClick = () => {
    if (hasUnsavedChanges) {
      toggleDialog("alertDialog");
    } else {
      navigate(-1);
    }
  };

  const handleSave = async () => {
    let updatedData = {
      businessId: data?.BusinessId,
      locationId: data?.LocationId,
      name: data?.Name,
      activityId: data?.UniqueId,
      mode: mode,
      link: {
        type: reviewLinkOption,
        url: reviewLinkUrl,
      },
      daysLater: data?.DaysLater,
      emailInfo: {
        type: "",
        frequency: "",
        subject: "",
        content: "",
        fullContent: "",
        sendText: "",
      },
      smsInfo: {
        type: "",
        frequency: "",
        text: "",
        fullContent: "",
      },
      schedule: {
        sendOnDays: selectedDays,
        sendAtTime: timeSlots.map((slot: any) => ({
          startTime: slot.start,
          endTime: slot.end,
        })),
        count: timeSlots.length,
      },
    };

    if (mode === "SMS") {
      updatedData = {
        ...updatedData,
        smsInfo: {
          type: type,
          frequency: mailSendingOption,
          text: subjectLine,
          fullContent: htmlContent,
        },
      };
    } else {
      updatedData = {
        ...updatedData,
        emailInfo: {
          type: type,
          frequency: mailSendingOption,
          subject: subjectLine,
          content: content,
          fullContent: htmlContent,
          sendText: buttonText,
        },
      };
    }

    try {
      const updateActivityConfig: IAPIConfig = {
        URL: `/Campaign/UpdateCampaignActivity?userUniqueId=${userUniqueId}`,
        method: "POST",
        payLoad: updatedData,
      };
      const response: any = await doApi(updateActivityConfig, "seedcore");
      if (response.data.err === false) {
        CustomToast(
          response.data.message || "Campaign Updated Successfully",
          "success"
        );
        navigate(-1);
      } else {
        CustomToast(
          response.data.message || "Campaign Not Updated Successfully",
          "error"
        );
      }
    } catch (error: any) {
      console.error("Error saving data:", error);
      CustomToast(error.message, "error");
    }
  };

  return (
    <Box className="review-email-edit-screen-container">
      <Box className="sms-edit-pagination">
        <Box className="back-button">
          <ArrowBackIcon
            sx={{
              marginBottom: "12px",
              color: "rgba(0, 0, 0, 0.56)",
              cursor: "pointer",
            }}
            onClick={handleExitClick}
          />
        </Box>
        <Box className="toggler">
          <Box className="toggler-buttons">
            <ArrowBackIcon
              sx={{
                color: "rgba(0, 0, 0, 0.56)",
                cursor: "pointer",
              }}
              onClick={() => handleNavigation("prev")}
            />
          </Box>
          <Box className="toggler-content">
            <Typography variant="h5">{data?.Name}</Typography>
            <Typography variant="body1" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {action === "update" ? campaignName : ""}
            </Typography>
          </Box>
          <Box className="toggler-buttons">
            <ArrowForwardIcon
              sx={{
                color: "rgba(0, 0, 0, 0.56)",
                cursor: "pointer",
              }}
              onClick={() => handleNavigation("next")}
            />
          </Box>
        </Box>
      </Box>
      {mode === "SMS" ? (
        <Box className="sms-container">
          <Box className="inner-container">
            <Box className="controls">
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginTop: 3 }}
              >
                SMS
              </Typography>
              <TextField
                label="content"
                variant="filled"
                fullWidth
                value={subjectLine}
                multiline
                rows={6}
                onChange={(e) => {
                  setSubjectLine(e.target.value);
                  setHasUnsavedChanges(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <RefreshIcon
                        color="primary"
                        sx={{ width: "24px", height: "24px", marginBottom: -2 }}
                        onClick={() => setSubjectLine("")}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className="buttons-container">
              <Box className="alret-dailog">
                {hasUnsavedChanges && (
                  <Alert variant="standard" severity="warning">
                    You have unsaved edits
                  </Alert>
                )}
              </Box>
              <Box className="buttons">
                <Button
                  sx={{ marginRight: 2 }}
                  variant="outlined"
                  color={hasUnsavedChanges ? "error" : undefined}
                  onClick={handleExitClick}
                >
                  {hasUnsavedChanges ? "Exit Without Saving" : "Exit"}
                </Button>
                <Button variant="contained" onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="preview-container">
            {/* Preview Area */}
            <Typography variant="subtitle1">Preview</Typography>
            <Box className="phone-frame">
              <PhoneFrameOuter className="outer" />
              <Box className="phone-content">
                <Box className="phoneImg">
                  <img src={phoneImg} alt="phone" />
                </Box>
                <Box className="sms-message">
                  <Box className="sms-content">
                    <Typography variant="body2" className="message-text">
                      {subjectLine}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 2 }}
                      className="message-text"
                    >
                      {reviewLinkUrl ? (
                        <Box component="span">{reviewLinkUrl}</Box>
                      ) : (
                        <span>Link</span>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <PhoneFrameOuter className="outer" />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="email-container">
          <Box className="inner-container">
            <Box className="controls">
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ marginTop: 3 }}
              >
                EMAIL
              </Typography>
              <TextField
                label="Subject"
                variant="filled"
                fullWidth
                value={subjectLine}
                onChange={(e) => {
                  setSubjectLine(e.target.value);
                  setHasUnsavedChanges(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <RefreshIcon
                        color="primary"
                        sx={{
                          width: "24px",
                          height: "24px",
                          marginBottom: -2,
                          cursor: "pointer",
                        }}
                        onClick={() => setSubjectLine("")}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Content"
                variant="filled"
                fullWidth
                multiline
                rows={6}
                value={content}
                onChange={(e) => {
                  setContent(e.target.value);
                  setHasUnsavedChanges(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <RefreshIcon
                        color="primary"
                        sx={{
                          width: "24px",
                          height: "24px",
                          marginBottom: -8,
                          cursor: "pointer",
                        }}
                        onClick={() => setContent("")}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Button Text"
                variant="filled"
                fullWidth
                value={buttonText}
                onChange={(e) => {
                  setButtonText(e.target.value);
                  setHasUnsavedChanges(true);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <RefreshIcon
                        color="primary"
                        sx={{
                          width: "24px",
                          height: "24px",
                          marginBottom: -2,
                          cursor: "pointer",
                        }}
                        onClick={() => setButtonText("")}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box className="buttons-container">
              <Box className="alret-dailog">
                {hasUnsavedChanges && (
                  <Alert variant="standard" severity="warning">
                    You have unsaved edits
                  </Alert>
                )}
              </Box>
              <Box className="buttons">
                <Button
                  sx={{ marginRight: 2 }}
                  variant="outlined"
                  color={hasUnsavedChanges ? "error" : undefined}
                  onClick={handleExitClick}
                >
                  {hasUnsavedChanges ? "Exit Without Saving" : "Exit"}
                </Button>
                <Button variant="contained" onClick={handleSave}>
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className="preview-container">
            {/* Preview Area */}
            <Typography variant="subtitle1">Preview</Typography>
            <Box className="phone-frame">
              <PhoneFrameOuter className="outer" />
              <Box className="phone-content">
                <Box className="phoneImg">
                  <img src={phoneImg} alt="phone" />
                </Box>
                <Box className="sms-message">
                  <Box
                    className="subject-bubble"
                    sx={{
                      width: "210px",
                      position: "absolute",
                      top: "-6px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      backgroundColor: "rgba(128, 128, 128, 0.6)", // Grey with 60% opacity
                      borderRadius: 1,
                      padding: "8px 12px",
                    }}
                  >
                    <Typography variant="body2">{subjectLine}</Typography>
                  </Box>

                  <Box className="phone-frame-content">
                    <Box>
                      <Typography variant="h6" className="title">
                        {profileData?.Name}
                      </Typography>
                      <Typography
                        variant="body1"
                        className="content"
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {content}
                        <br />
                      </Typography>
                    </Box>
                    <Box className="submit-btn">
                      <Button variant="contained" color="primary">
                        {buttonText}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <PhoneFrameOuter className="outer" />
            </Box>
          </Box>
        </Box>
      )}

      {/* alert dialog */}
      <AlertCustomDialog
        open={dialogState.alertDialog}
        onClose={() => toggleDialog("alertDialog")}
        title="Are you sure you want to exit without saving?"
        minWidth="600px"
      >
        <Box
          className="line"
          sx={{
            width: "100%",
            height: "0.0625rem",
            opacity: "0.2",
            background: "var(--Schemes-On-Secondary, red)",
          }}
        ></Box>

        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              navigate(-1);
            }}
          >
            Yes, exit without saving
          </Button>
          <Button
            variant="contained"
            onClick={() => toggleDialog("alertDialog")}
          >
            Never mind
          </Button>
        </Stack>
      </AlertCustomDialog>
    </Box>
  );
};

export default ReviewSMSEditScreen;
