/* eslint-disable react-hooks/exhaustive-deps */

import "./TopicStartegyBuilder.scss";

import { ArrowForward, RemoveCircleOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import React, { useEffect, useMemo, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add"; // Filled Add (+) icon
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Close } from "@mui/icons-material";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DoneIcon from "@mui/icons-material/Done";
import DownloadIcon from "@mui/icons-material/Download";
import GetKeywordsTable from "./GetKeywordsTable";
import IntentClusters from "./IntentClusters";
import Loader from "../../utils/loader/Loader";
import Stepper from "./Stepper";
import axios from "axios";
import { urlConstants } from "../../components/constants/globalConstants";

//import Stepper from "./Stepper";

interface firstStep {
  status: string;
  output: string;
  downloadUrl: string;
}

const LISTBOX_HEIGHT = 250;
const ITEM_HEIGHT = 40;

const VirtualizedListbox = React.forwardRef<HTMLDivElement, any>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const items = React.Children.toArray(children);

    return (
      <div ref={ref} {...other}>
        <FixedSizeList
          height={LISTBOX_HEIGHT}
          width="100%"
          itemSize={ITEM_HEIGHT}
          itemCount={items.length}
        >
          {({ index, style }: ListChildComponentProps) => (
            <div
              style={{
                ...style,
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {items[index]}
            </div>
          )}
        </FixedSizeList>
      </div>
    );
  }
);

const TopicStartegyBuilder = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const countryCodeRef = useRef(true);
  const [loading, setLoading] = useState(false);

  // firsttab
  const [url, setUrl] = useState("");
  const [csvFile, setCsvFile] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [firstStepData, setFirstStepData] = useState<firstStep>({
    status: "",
    output: "",
    downloadUrl: "",
  });
  const [isSeed, setIsSeed] = useState(false);
  const [seed, setSeed] = useState("");

  //secondTab
  const [selectedOptions, setSelectedOptions] = useState<string[]>(["all"]);
  const [secondTabData, setSecondTabData] = useState<any>([]);

  const [secondTabCsv, setSecondTabCsv] = useState<string>("");
  const options = [
    { key: "all", value: "All" },
    { key: "semrush", value: "Semrush" },
    { key: "google_suggest", value: "Google Suggest" },
    { key: "gsc", value: "GSC" },
    { key: "gbp", value: "GBP" },
  ];
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === "all") {
      setSelectedOptions(checked ? options.map((opt) => opt.key) : []);
    } else {
      let updatedOptions = checked
        ? [...selectedOptions, name]
        : selectedOptions.filter((item) => item !== name);

      if (updatedOptions.length === options.length - 1) {
        updatedOptions = ["all", ...updatedOptions];
      } else {
        updatedOptions = updatedOptions.filter((item) => item !== "all");
      }

      setSelectedOptions(updatedOptions);
    }
  };

  //thirdTab
  const [brand, setBrand] = useState("");
  const [location, setLocation] = useState("");
  const [thirdTabData, setThirdTabData] = useState<any>([]);
  const [thirdTabCsv, setThirdTabCsv] = useState<string>("");

  //fourthTab
  const [algorithm, setAlgorithm] = useState("");
  const [overlap, setOverlap] = useState("");
  const [countryCode, setCountryCode] = useState("US");
  const [locationFourthTab, setLocationFourthTab] = useState("");
  const [isoDetails, setIsoDetails] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [fourthTabCsv, setFourthTabCsv] = useState<string>("");
  const [forNextStepCsv, setForNextStepCsv] = useState<string>("");
  const [stepper, setStepper] = useState<any>([]);
  const [clusters, setClusters] = useState<any>([]);
  const [groupClusters, setGroupClusters] = useState<any>([]);
  const [firstTimeRunFourthTab, setFirstTimeRunFourthTab] = useState(false);
  const [firstTimeRunFifthTab, setFirstTimeRunFifthTab] = useState(false);

  //fifthTab
  const [tightness, setTightness] = useState("");
  const [intentClusters, setIntentCluster] = useState<any>([]);
  const [finalCsv, setFinalCsv] = useState<string>("");
  const [fifthStepper, setFifthStepper] = useState<any>([]);
  const [groupIntentClusters, setGroupIntentClusters] = useState<any>([]);
  const [forNextFifthStepCsv, setForNextFifthStepCsv] = useState<string>("");

  const totalTabs = 5;
  const [completedSteps, setCompletedSteps] = useState<number[]>([0]);

  const handleNext = () => {
    if (tabIndex === 3) {
      if (!forNextStepCsv) {
        CustomToast("please select file from stepper.", "error");
        return;
      }
    }
    if (tabIndex < totalTabs - 1) {
      setCompletedSteps([...completedSteps, tabIndex + 1]);
      setTabIndex(tabIndex + 1);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (completedSteps.includes(newValue)) {
      setTabIndex(newValue);
    }
  };

  // first tab Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // first tab Handle rows per page change
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCsvUpload = (event: any) => {
    setCsvFile(event.target.files[0]);
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleConfirmSeed = () => {
    const tableDataKeywords = firstStepData.output.includes("\n")
      ? firstStepData.output.trim().split("\n")
      : firstStepData.output.trim().split(",");
    const updatedSeeds = [...tableDataKeywords, seed];
    handleSubmitForFirstTab(null, updatedSeeds);
    setIsSeed(false);
  };

  const handleRemoveSeedKeyword = (index: number) => {
    // Extract keywords from firstStepData
    const tableDataKeywords = firstStepData.output.includes("\n")
      ? firstStepData.output.trim().split("\n")
      : firstStepData.output.trim().split(",");
    const updatedTableDataKeywords = tableDataKeywords.filter(
      (_, i) => i !== index
    );
    handleSubmitForFirstTab(null, updatedTableDataKeywords);
  };

  const handleSubmitForFirstTab = async (
    csv: any,
    finalConfirmSeeds: any = []
  ) => {
    setStepper([]);
    setSecondTabData([]);
    setThirdTabData([]);
    setClusters([]);
    setIntentCluster([]);
    setStepper([]);
    setGroupClusters([]);
    setGroupIntentClusters([]);
    setFifthStepper([]);
    setFirstTimeRunFourthTab(false);
    setFirstTimeRunFifthTab(false);
    const seedInput =
      "Given a list of offerings, I want you to help me determine the seed keywords for a business. We will do this in the following steps.\n\nSteps:\n1: Use a list of offerings to determine the business's core functions.\n2: Use the core functions, along with information from NAICS, ISIC or GICS to determine an industry. \n3: Find seed keywords for the business. Seed keywords are typically short, primary, broad terms highly relevant to the niche or industry. Remember that while seed keywords are generally broad, ensure they are not too vague. a Bread is a better seed keyword than a food for a bakery\n4: Run a check for each seed keyword if it is a search engine-optimized phrase likely to be used by a human. A searcher optimized phrase is usually simple in language, focusing on main keywords, devoid of unnecessary words and sounds natural.\n5: If it is optimized, do nothing. If not, update to optimized version.\n\nOutput as a final, single list of keywords line-separated. Only output the final list, do not output the steps.\n\nseed:\n{input_1}\n";
    const formData = new FormData();
    formData.append("csvFile", csv);
    formData.append("promptTemplate", seedInput);
    formData.append("additionalKeywords", JSON.stringify(finalConfirmSeeds));

    try {
      setLoading(true);
      const response = await axios.post(
        `${urlConstants.PROMPT_URL}/api/workflows/process`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const finalRes: firstStep = response.data;
      if (finalRes.status === "Successful") {
        setFirstStepData(finalRes);
      } else {
        CustomToast(finalRes.output, "error");
        setFirstStepData({
          status: "",
          output: "",
          downloadUrl: "",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFirstStepData({
        status: "",
        output: "",
        downloadUrl: "",
      });
    } finally {
      setLoading(false);
    }
  };

  const getIsoDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${urlConstants.PROMPT_URL}/api/workflows/iso-details`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const finalRes: any = response.data;
      setIsoDetails(finalRes);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const getLocations = async (country: string) => {
    if (!country) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `${urlConstants.PROMPT_URL}/api/workflows/states/${country}`,
        { headers: { "Content-Type": "application/json" } }
      );

      const locationsData = response.data || [];
      setLocations(locationsData);

      // Dynamic timeout based on data length
      const timeoutDuration = Math.min(
        Math.max(locationsData.length * 10, 2000),
        20000
      );
      setTimeout(() => {
        setLoading(false);
      }, timeoutDuration);
    } catch (error) {
      console.error("Error fetching locations:", error);
      setLoading(false);
    }
  };

  // Memoize locations for performance
  const memoizedOptions = useMemo(() => locations, [locations]);

  useEffect(() => {
    if (
      Array.isArray(isoDetails) &&
      isoDetails.length > 0 &&
      countryCodeRef.current
    ) {
      getLocations(countryCode);
      countryCodeRef.current = false;
    }
  }, [isoDetails, countryCodeRef.current]);

  useEffect(() => {
    if (tabIndex === 3) {
      getIsoDetails();
    }
  }, [tabIndex]);

  const handleSubmitForSecondTab = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${urlConstants.PROMPT_URL}/api/workflows/execute`,
        { apis: selectedOptions, database: "us", display_limit: 5 }
      );

      const finalRes = response.data;
      if (finalRes.success) {
        let transformedArray: any[] = [];

        Object.keys(finalRes).length > 0 &&
          Object.keys(finalRes.data).length > 0 &&
          Object.keys(finalRes.data).forEach((category) => {
            options.forEach(({ key, value }) => {
              if (key !== "all" && finalRes.data[category][key]) {
                finalRes.data[category][key].forEach((item: any) => {
                  transformedArray.push({
                    source: value,
                    keyword: typeof item === "string" ? item : item.keyword,
                  });
                });
              }
            });
          });
        setSecondTabData(transformedArray);
        setSecondTabCsv(finalRes?.csvDownloadLink);
      } else {
        CustomToast(finalRes.message, "error");
        setSecondTabData([]);
        setSecondTabCsv("");
      }
    } catch (error: any) {
      CustomToast(error?.message, "error");
      setSecondTabData([]);
      setSecondTabCsv("");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitForThirdTab = async () => {
    const formData = new FormData();
    formData.append("brand", brand);
    formData.append("location", location);
    formData.append(
      "prompt",
      'You are an AI assistant tasked with processing a list of search keywords. Your goal is to identify all instances of brand names and location names within the keywords and replace them with the provided brand and location.  \n\nTask Details:\n1. Identify all occurrences of brand names and location names in the given list of keywords.\n2. Replace any brand with the provided brand:{input_3}.\n3. Replace any location with the provided location:{input_4}.\n4. Maintain the keyword structure but update all brand and location references.\n5. Ensure all instances are correctly replaced, even if a keyword contains multiple locations or brands.\n\nConstraints:\n- Do not modify non-brand or non-location words.\n- Do not ask for confirmation or clarification.\n- Do not summarize or explain your process.\n- Immediately return the **full transformed list**.\n- Output the result in a **JSON array format**.\n\nExample Brand and Location provided as below: \n| brand | location |\n| --- | --- |\n| Starbucks | New York |\n\nExample Input Keywords:\n["Applebees near me", "Car accident lawyer Chicago", "Outback Steakhouse Los Angeles"]\n\nExpected Output:\n["Starbucks near me", "Car accident lawyer New York", "Starbucks New York"]\n\n\nBrand and Location:\n{input_2}\n\nKeywords List:\n{input_1}\n\nProcess the **entire list** and output the full **transformed** list (as VALID JSON List).'
    );
    try {
      setLoading(true);
      const response = await axios.post(
        `${urlConstants.PROMPT_URL}/api/workflows/generate-prompt`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const finalRes: any = response.data;
      if (finalRes.success) {
        setThirdTabData(
          finalRes.rawOutput !== "" &&
            finalRes.duplicatremovedKeywords.includes("\n")
            ? finalRes.duplicatremovedKeywords.split("\n")
            : finalRes.duplicatremovedKeywords.split(",")
        );
        setThirdTabCsv(finalRes?.csvDownloadLink);
      } else {
        CustomToast(finalRes.message, "error");
        setThirdTabData([]);
        setThirdTabCsv("");
      }
    } catch (error: any) {
      CustomToast(error.message, "error");
      setThirdTabData([]);
      setThirdTabCsv("");
    } finally {
      setLoading(false);
    }
  };
  const extractLocationAndCountry = (stateName: string) => {
    const parts = stateName.split(",");
    if (parts.length < 2) return { location: stateName, country: "" };

    return {
      location: parts[0].trim(),
      country: parts[parts.length - 1].trim(),
    };
  };
  const handleSubmitForFourthTab = async () => {
    try {
      setLoading(true);
      const result = extractLocationAndCountry(locationFourthTab);
      const response = await axios.post(
        `${urlConstants.PROMPT_URL}/api/workflows/process-keywords`,
        {
          country: result.country,
          location: result.location,
          algorithm: algorithm,
          urlOverlap: parseInt(overlap, 10),
        }
      );

      const finalRes = response.data;
      const transformedData = {
        clusters: finalRes.clusters.map((cluster: any) => ({
          clusterName: cluster.keywords[0],
          searchVolume: cluster.searchVolume,
          keywordsCount: cluster.keywords.length,
          keywords: cluster.keywords,
        })),
      };
      setClusters(transformedData?.clusters);
      setFourthTabCsv(finalRes?.downloadURL);
      setStepper([...stepper, finalRes?.downloadURL]);
      setGroupClusters([
        ...groupClusters,
        { [finalRes?.downloadURL]: transformedData?.clusters },
      ]);
      setFirstTimeRunFourthTab(true);
    } catch (error: any) {
      CustomToast(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitForFifthTab = async () => {
    try {
      setLoading(true);
      var data = new FormData();
      data.append(
        "prompt",
        `Group the following intent clusters into topics using HDBSCAN, a public density‑based clustering algorithm.
          Use the provided similarity threshold to set cluster tightness: Low (70%), Medium(88%), or High(93%). 
          You will receive two inputs:
          Setting: Low, Medium, or High
          Clusters: an array of intent cluster objects (each representing the head term of a cluster).
          Inputs:
          Setting: Medium
          Clusters: 
          {input_1}
          Response should contain an array of objects and each object has two keys as below:
          1. topic: a string representing the topic name.
          2. clusters: an array containing the clusters assigned to that topic.
          Sample Response:
          [{"topic":"Bun","clusters":["bundesliga", ...]},{"topic":"Tea","clusters":["bubble tea stores near me", ...]}, ...]
          Return a valid JSON ONLY.`
      );
      data.append("setting", tightness);

      const response = await axios.post(
        `${urlConstants.PROMPT_URL}/api/workflows/process-file`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const finalRes = response.data;
      const modifiedData =
        finalRes?.processedClusters.length > 0
          ? finalRes?.processedClusters.map((item: any) => ({
              topicName: item["topic"],
              searchVolume: item["SearchVolume"],
              clusters: item["clusters"],
              keywords: item["keywords"],
            }))
          : [];
      setIntentCluster(modifiedData);
      const finalCsvFile = finalRes.csvFilePath.split("/");
      setFinalCsv(finalCsvFile[finalCsvFile.length - 1]);
      setForNextFifthStepCsv(finalCsvFile[finalCsvFile.length - 1]);
      setFifthStepper([...fifthStepper, finalCsvFile[finalCsvFile.length - 1]]);
      setGroupIntentClusters([
        ...groupIntentClusters,
        { [finalCsvFile[finalCsvFile.length - 1]]: modifiedData },
      ]);
      setFirstTimeRunFifthTab(true);
    } catch (error: any) {
      CustomToast(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const ForNextTabProcess = async () => {
    try {
      setLoading(true);
      const extractFileName = forNextStepCsv.split("/");
      const finalExtractCsv = extractFileName[extractFileName.length - 1];
      await axios.post(
        `${urlConstants.PROMPT_URL}/api/workflows/upload-content`,
        { fileName: `${finalExtractCsv}` }
      );
    } catch (error: any) {
      CustomToast(error?.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (forNextStepCsv) {
      ForNextTabProcess();
      setFourthTabCsv(forNextStepCsv);
      const currentCluster = groupClusters.find(
        (cluster: any) => Object.keys(cluster)[0] === forNextStepCsv
      );
      const clustersArray = currentCluster
        ? currentCluster[forNextStepCsv]
        : [];
      setClusters(clustersArray);
    }
  }, [forNextStepCsv]);

  useEffect(() => {
    if (forNextFifthStepCsv) {
      setFinalCsv(forNextFifthStepCsv);
      const currentCluster = groupIntentClusters.find(
        (cluster: any) => Object.keys(cluster)[0] === forNextFifthStepCsv
      );
      const clustersArray = currentCluster
        ? currentCluster[forNextFifthStepCsv]
        : [];
      setIntentCluster(clustersArray);
    }
  }, [forNextFifthStepCsv]);

  const isSubmitDisabled =
    !algorithm || !overlap || !countryCode || !locationFourthTab;

  return (
    <Box className="startegy-container">
      <Loader spinning={loading}>
        <Box className="container">
          <Box className="title-container">
            <Box className="title">
              <Typography
                variant="h4"
                sx={{
                  color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
                  textAlign: "center",
                  fontFeatureSettings: "'liga' off, 'clig' off",
                }}
              >
                Topic Strategy Builder
              </Typography>
            </Box>
          </Box>

          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            className="tabs-container"
            variant="scrollable"
            scrollButtons="auto"
          >
            {[
              { key: "seed-keywords", value: 0, label: "Seed Keywords" },
              { key: "get-keywords", value: 1, label: "Get Keywords" },
              { key: "clean-filter", value: 2, label: "Clean & Filter" },
              { key: "intent-clusters", value: 3, label: "Intent Clusters" },
              { key: "topic-clusters", value: 4, label: "Topic Clusters" },
            ].map((tab) => (
              <Tab
                key={tab.key}
                value={tab.value}
                className="tab"
                disabled={!completedSteps.includes(tab.value)} // Disable uncompleted steps
                sx={{
                  opacity: tabIndex === tab.value ? 1 : 0.5,
                  transition: "opacity 0.3s ease-in-out",
                }}
                label={
                  <Box className="tab-label">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        rowGap: "0.25rem",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography className="tab-label-text">
                        STEP {tab.value + 1}
                      </Typography>
                      <Typography className="tab-label-text">
                        {tab.label}
                      </Typography>
                    </Box>
                    <ArrowForward />
                  </Box>
                }
              />
            ))}
          </Tabs>

          {tabIndex === 0 && (
            <>
              <Box className="first-tab">
                <Box className="upload-container">
                  <TextField
                    id="enter-url"
                    label="Enter URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    variant="filled"
                    size="medium"
                    sx={{ width: 378 }}
                  />

                  <Typography variant="body1">OR</Typography>

                  <TextField
                    id="upload-csv"
                    label="Upload CSV"
                    value={csvFile ? csvFile.name : ""}
                    variant="filled"
                    size="medium"
                    sx={{ width: 378 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachFileIcon
                            style={{ cursor: "pointer" }}
                            onClick={handleIconClick}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleCsvUpload}
                  />
                </Box>

                {/* Submit Button */}
                <Button
                  color="primary"
                  variant="outlined"
                  size="large"
                  onClick={() => handleSubmitForFirstTab(csvFile || url)}
                  disabled={!url && !csvFile}
                >
                  Submit
                </Button>
              </Box>
              <Divider
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              />
              <Card className="card-container">
                <CardHeader
                  className="card-header"
                  title={
                    <Box className="card-header-inner">
                      <Typography
                        variant="h5"
                        sx={{
                          alignSelf: "stretch",
                          color: "rgba(0, 0, 0, 0.87) !important",
                          fontFeatureSettings: "'liga' off, 'clig' off",
                        }}
                      >
                        Seed Keywords
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "rgba(0, 0, 0, 0.87)",
                          opacity: 0.6,
                          fontFeatureSettings: "'liga' off, 'clig' off",
                        }}
                      >
                        {firstStepData.output !== ""
                          ? firstStepData.output.includes("\n")
                            ? firstStepData.output.trim().split("\n").length
                            : firstStepData.output.trim().split(",").length
                          : 0}{" "}
                        total keywords
                      </Typography>
                    </Box>
                  }
                />
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow></TableRow>
                      </TableHead>
                      <TableBody>
                        {/* Processed Keywords */}
                        {(firstStepData.output !== "" &&
                        firstStepData.output.includes("\n")
                          ? firstStepData.output.split("\n")
                          : firstStepData.output.split(",")
                        )
                          .map((word) => word.trim()) // Trim whitespace
                          .filter(Boolean) // Remove empty values
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          ) // Pagination
                          .map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <TableCell
                                sx={{
                                  padding: "10px !important",
                                  borderBottom:
                                    "1px solid rgba(0, 0, 0, 0.06) !important",
                                  width: "90%",
                                }}
                              >
                                {row}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "10px !important",
                                  borderBottom:
                                    "1px solid rgba(0, 0, 0, 0.06) !important",
                                  width: "10%",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleRemoveSeedKeyword(index)}
                                  className="icon-button"
                                >
                                  <RemoveCircleOutlined
                                    style={{
                                      color: "#000000",
                                      opacity: "0.12",
                                    }}
                                  />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}

                        {/* Add Seed Input Row */}
                        {isSeed && (
                          <TableRow>
                            <TableCell>
                              <TextField
                                value={seed}
                                onChange={(e) => setSeed(e.target.value)}
                                size="small"
                                variant="outlined"
                                placeholder="Enter Seed Keyword"
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="success"
                                onClick={handleConfirmSeed}
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => {
                                  setSeed("");
                                  setIsSeed(false);
                                }}
                              >
                                <Close />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* Pagination */}
                  <TablePagination
                    rowsPerPageOptions={[100, 200, 300, 400, 500, 1000]}
                    component="div"
                    count={
                      firstStepData.output !== ""
                        ? firstStepData.output.includes("\n")
                          ? firstStepData.output.trim().split("\n").length
                          : firstStepData.output.trim().split(",").length
                        : 0
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <Box className="table-footer">
                    <Button
                      size="medium"
                      color="primary"
                      variant="text"
                      className="addicon"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setSeed("");
                        setIsSeed(true);
                      }}
                    >
                      {" "}
                      Add Seed
                    </Button>
                  </Box>
                </Paper>
              </Card>
              <Box className="footer-icons">
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="addicon"
                  startIcon={<DownloadIcon />}
                  disabled={firstStepData.downloadUrl === ""}
                  onClick={() => {
                    window.open(
                      `${urlConstants.PROMPT_URL}/api/workflows${firstStepData.downloadUrl}`,
                      "_blank"
                    );
                  }}
                >
                  {" "}
                  CSV
                </Button>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className="addicon"
                  onClick={handleNext}
                  disabled={firstStepData.output === ""}
                  endIcon={<ArrowForward />}
                >
                  {" "}
                  Next
                </Button>
              </Box>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <Box className="secondTab">
                <FormGroup className="controls">
                  {options.map((option) => (
                    <FormControlLabel
                      key={option.key}
                      control={
                        <Checkbox
                          checked={selectedOptions.includes(option.key)}
                          onChange={handleCheckboxChange}
                          name={option.key}
                        />
                      }
                      label={option.value}
                    />
                  ))}
                </FormGroup>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={handleSubmitForSecondTab}
                >
                  Submit
                </Button>
              </Box>
              <Divider
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              />
              <GetKeywordsTable apiResponse={secondTabData} tab={1} />
              <Box className="footer-icons">
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="addicon"
                  startIcon={<DownloadIcon />}
                  disabled={secondTabCsv === ""}
                  onClick={() => {
                    window.open(
                      `${urlConstants.PROMPT_URL}/api/workflows/download-csv/${secondTabCsv}`,
                      "_blank"
                    );
                  }}
                >
                  CSV
                </Button>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className="addicon"
                  onClick={handleNext}
                  disabled={secondTabData.length === 0}
                  endIcon={<ArrowForward />}
                >
                  Next
                </Button>
              </Box>
            </>
          )}
          {tabIndex === 2 && (
            <>
              <Box className="thirdTab">
                <Box className="controls">
                  <TextField
                    id="enter-brand"
                    label="Brand"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                    variant="filled"
                    size="medium"
                    sx={{ width: 378 }}
                  />
                  <TextField
                    id="enter-location"
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    variant="filled"
                    size="medium"
                    sx={{ width: 378 }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  disabled={!brand || !location}
                  onClick={handleSubmitForThirdTab}
                >
                  Submit
                </Button>
              </Box>
              <Divider
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              />
              <GetKeywordsTable apiResponse={thirdTabData} tab={2} />
              <Box className="footer-icons">
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="addicon"
                  startIcon={<DownloadIcon />}
                  disabled={thirdTabCsv === ""}
                  onClick={() => {
                    window.open(
                      `${urlConstants.PROMPT_URL}/api/workflows/download-llm/${thirdTabCsv}`,
                      "_blank"
                    );
                  }}
                >
                  CSV
                </Button>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className="addicon"
                  onClick={handleNext}
                  disabled={thirdTabData.length === 0}
                  endIcon={<ArrowForward />}
                >
                  Next
                </Button>
              </Box>
            </>
          )}
          {tabIndex === 3 && (
            <>
              <Box className="fourthTab">
                <Box className="controls">
                  <FormControl sx={{ width: 180 }}>
                    <InputLabel id="algorithm-select-label">
                      Algorithm
                    </InputLabel>
                    <Select
                      labelId="algorithm-select-label"
                      id="algorithm-select"
                      value={algorithm}
                      label="Age"
                      disabled={firstTimeRunFourthTab}
                      onChange={(e) => setAlgorithm(e.target.value)}
                    >
                      <MenuItem value="Centroid">Centroid</MenuItem>
                      <MenuItem value="Agglomerative">Agglomerative</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: 180 }}>
                    <InputLabel id="overlap-select-label">Overlap</InputLabel>
                    <Select
                      labelId="overlap-select-label"
                      id="overlap-select"
                      value={overlap}
                      label="overlap"
                      disabled={firstTimeRunFourthTab}
                      onChange={(e) => setOverlap(e.target.value)}
                    >
                      {[...Array(10)].map((_, index) => (
                        <MenuItem key={index + 1} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: 180 }}>
                    <Autocomplete
                      options={isoDetails}
                      getOptionLabel={(option: any) => option}
                      value={countryCode}
                      disabled={firstTimeRunFourthTab}
                      onChange={(event: any, newValue: any) => {
                        countryCodeRef.current = true;
                        setCountryCode(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country Code" />
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: 180 }}>
                    <Autocomplete
                      options={memoizedOptions || []}
                      disableListWrap
                      ListboxComponent={VirtualizedListbox}
                      getOptionLabel={(option) => option?.state_name ?? ""}
                      loading={loading}
                      disabled={firstTimeRunFourthTab}
                      value={
                        Array.isArray(locations) &&
                        locations.length > 0 &&
                        (locations.find(
                          (loc: any) => loc.state_name === locationFourthTab
                        ) ||
                          null)
                      }
                      onChange={(event, newValue) =>
                        setLocationFourthTab(
                          newValue ? newValue.state_name : ""
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Location"
                          placeholder={
                            loading ? "Loading locations..." : "Select location"
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading && (
                                  <CircularProgress color="inherit" size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={handleSubmitForFourthTab}
                  disabled={isSubmitDisabled || firstTimeRunFourthTab}
                >
                  Submit
                </Button>
              </Box>
              <Divider
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              />
              <IntentClusters
                data={clusters}
                tab={3}
                setAlgorithmProp={setAlgorithm}
                setOverlapProp={setOverlap}
                OnSubmitFunc={handleSubmitForFourthTab}
              />
              <Box className="footer-icons">
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="addicon"
                  startIcon={<DownloadIcon />}
                  disabled={fourthTabCsv === ""}
                  onClick={() => {
                    window.open(
                      `${urlConstants.PROMPT_URL}/api/workflows${fourthTabCsv}`,
                      "_blank"
                    );
                  }}
                >
                  CSV
                </Button>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className="addicon"
                  onClick={handleNext}
                  endIcon={<ArrowForward />}
                  disabled={forNextStepCsv === ""}
                >
                  Next
                </Button>
              </Box>
              <Stepper
                data={stepper || []}
                nextStepCsv={setForNextStepCsv}
                csvFile={forNextStepCsv}
              />
            </>
          )}
          {tabIndex === 4 && (
            <>
              <Box className="fifthTab">
                <Box className="controls">
                  <FormControl sx={{ width: 180 }}>
                    <InputLabel id="tightness-select-label">
                      Tightness
                    </InputLabel>
                    <Select
                      labelId="tightness-select-label"
                      id="tightness-select"
                      value={tightness}
                      label="tightness"
                      disabled={firstTimeRunFifthTab}
                      onChange={(e) => setTightness(e.target.value)}
                    >
                      <MenuItem value="High">High</MenuItem>
                      <MenuItem value="Medium">Medium</MenuItem>
                      <MenuItem value="Low">Low</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  disabled={!tightness || firstTimeRunFifthTab}
                  onClick={handleSubmitForFifthTab}
                >
                  Submit
                </Button>
              </Box>
              <Divider
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  alignSelf: "stretch",
                }}
              />
              <IntentClusters
                dataRes={intentClusters}
                tab={4}
                setTightnessProp={setTightness}
                OnSubmitFunc={handleSubmitForFifthTab}
              />
              <Box className="footer-icons">
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  className="addicon"
                  startIcon={<DownloadIcon />}
                  disabled={finalCsv === ""}
                  onClick={() => {
                    window.open(
                      `${urlConstants.PROMPT_URL}/api/workflows/download/${finalCsv}`,
                      "_blank"
                    );
                  }}
                >
                  CSV
                </Button>
              </Box>
              <Stepper
                data={fifthStepper || []}
                nextStepCsv={setForNextFifthStepCsv}
                csvFile={forNextFifthStepCsv}
              />
            </>
          )}
        </Box>
      </Loader>
    </Box>
  );
};

export default TopicStartegyBuilder;
