/* eslint-disable react-hooks/exhaustive-deps */

import "./NewBusinessProfile.scss";

import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import AddFilled from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DoneIcon from "@mui/icons-material/Done";
import Loader from "../../utils/loader/Loader";
import ProductsTable from "../businessProfile/ProductsTable";
import RemoveCircleOutlined from "@mui/icons-material/RemoveCircleOutlined";
import { useBusinessProfileApis } from "../../custom_hooks/useBusinessProfileApis";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const timezones = [
  "UTC-05:00 Eastern Time",
  "UTC-06:00 Central Time",
  "UTC-07:00 Mountain Time",
  "UTC-08:00 Pacific Time",
  "UTC-09:00 Alaska Time",
  "UTC-10:00 Hawaii Time",
];

const NewBusinessProfile = () => {
  const location = useLocation();
  const {
    GetBusinessProfilesDataByUniqueID,
    UpdateBusinessProfilesByUniqueID,
    businessProfilesDataLoading,
    businessUpdateProfilesDataLoading,
  } = useBusinessProfileApis();

  const { businessData } = location.state || {};
  const businessRef = useRef(true);

  const profileData = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [businessDataDetails, setBusinessDataDetails] = useState({
    Website: profileData?.Website || "",
    Name: profileData?.Name || "",
    Category: profileData?.Category || "",
    Description: profileData?.Description || "",
    LocationType: profileData?.LocationType || "",
    BusinessObjective: profileData?.BusinessObjective || "",
    BrandVoice: profileData?.BrandVoice || "",
    CustomerPersonas: profileData?.CustomerPersonas || [],
    Competitors: profileData?.Competitors || [],
    Locations: profileData?.Locations || [],
  });

  const [isEditing, setIsEditing] = useState({
    isKeyPeople: false,
    isUrl: false,
    isLocation: false,
  });
  const [newPeople, setNewPeople] = useState({
    PersonName: "",
    PersonDescription: "",
    Expertise: "",
  });
  const [newLocation, setNewLocation] = useState({
    Name: "",
    Address1: "",
    TimeZone: "",
    Integrations: "",
  });
  const [newCompetitor, setNewCompetitor] = useState({ Website: "" });
  const [editingCell, setEditingCell] = useState<{
    row: number;
    field: string;
  } | null>(null);
  const handleCellClick = (rowIndex: number, field: string) => {
    setEditingCell({ row: rowIndex, field });
  };

  const handleInputChange = (e: any, rowIndex: number, field: string) => {
    const updatedRows = [...businessDataDetails.CustomerPersonas];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      [field]: e.target.value,
    };
    setBusinessDataDetails({
      ...businessDataDetails,
      CustomerPersonas: updatedRows,
    });
  };

  useEffect(() => {
    if (businessRef.current) {
      GetBusinessProfilesDataByUniqueID(businessData.UniqueId);
      businessRef.current = false;
    }
  }, [businessData]);

  const handleInputChangeCompetitor = (
    e: any,
    rowIndex: number,
    field: string
  ) => {
    const updatedRows = [...businessDataDetails.Competitors];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      [field]: e.target.value,
    };
    setBusinessDataDetails({
      ...businessDataDetails,
      Competitors: updatedRows,
    });
  };

  const handleInputChangeLocation = (
    e: any,
    rowIndex: number,
    field: string
  ) => {
    const updatedRows = [...businessDataDetails.Locations];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      [field]: e.target.value,
    };
    setBusinessDataDetails({ ...businessDataDetails, Locations: updatedRows });
  };

  const handleBlur = () => {
    setEditingCell(null);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setEditingCell(null);
    }
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleInputPeopleChange = (field: string, value: string) => {
    setNewPeople((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleRemovePerson = (index: number) => {
    setBusinessDataDetails((prevDetails) => ({
      ...prevDetails,
      CustomerPersonas: prevDetails.CustomerPersonas.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  const handleRemoveCompetitor = (index: number) => {
    setBusinessDataDetails((prevDetails) => ({
      ...prevDetails,
      Competitors: prevDetails.Competitors.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  const handleInputCompetitorChange = (field: string, value: string) => {
    setNewCompetitor((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleRemoveLocation = (index: number) => {
    setBusinessDataDetails((prevDetails) => ({
      ...prevDetails,
      Competitors: prevDetails.Competitors.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  const UpdateBusinessProfile = () => {
    const payLoad: any = { ...profileData, ...businessDataDetails };
    UpdateBusinessProfilesByUniqueID(businessData?.UniqueId, payLoad);
    GetBusinessProfilesDataByUniqueID(businessData.UniqueId);
    setHasUnsavedChanges(false);
  };

  return (
    <Box className="new-profile-container">
      <Loader
        spinning={
          businessProfilesDataLoading || businessUpdateProfilesDataLoading
        }
      >
        <Box className="inner-container">
          <Box className="profile-pagination">
            <Box className="profile-header">
              <Typography variant="h4">Profile</Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "2.125rem !important", opacity: 0.6 }}
              >
                | {profileData?.Name}
              </Typography>
            </Box>
            <Box className="tabs-container">
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Business" className="tab"  value={0} />
                <Tab label="Offerings" className="tab" value={1}/>
              </Tabs>
            </Box>
          </Box>
          {selectedTab === 0 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", columnGap: "12px" }}>
                  {hasUnsavedChanges && (
                    <Alert variant="standard" severity="warning">
                      You have unsaved edits
                    </Alert>
                  )}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={UpdateBusinessProfile}
                  >
                    Save Edits
                  </Button>
                </Box>
              </Box>
              <Box className="website-container">
                <Box className="inner-container">
                  <Box className="text-container">
                    <Box className="text-group">
                      <Typography variant="subtitle2">
                        <span style={{ color: "#CD3535" }}>*</span>
                        <span style={{ color: "#171D1A)" }}>Website</span>{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Provide the official url of your business website
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    id="website"
                    label=""
                    placeholder="type something here"
                    value={businessDataDetails.Website}
                    onChange={(e) =>
                      setBusinessDataDetails({
                        ...businessDataDetails,
                        Website: e.target.value,
                      })
                    }
                    variant="outlined"
                    size="small"
                    sx={{ width: 720, height: 40 }}
                  />
                </Box>
              </Box>
              <Box className="business-container">
                <Box className="inner-container">
                  <Box className="text-container">
                    <Box className="text-group">
                      <Typography variant="subtitle2">
                        <span style={{ color: "#CD3535" }}>*</span>
                        <span style={{ color: "#171D1A)" }}>
                          Business Name
                        </span>{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Provide the official name of your business
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    id="businessName"
                    label=""
                    placeholder="type something here"
                    value={businessDataDetails.Name}
                    onChange={(e) => {
                      setHasUnsavedChanges(true);
                      setBusinessDataDetails({
                        ...businessDataDetails,
                        Name: e.target.value,
                      });
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ width: 720, height: 40 }}
                  />
                </Box>
              </Box>
              <Box className="category-container">
                <Box className="inner-container">
                  <Box className="text-container">
                    <Box className="text-group">
                      <Typography variant="subtitle2">
                        <span style={{ color: "#CD3535" }}>*</span>
                        <span style={{ color: "#171D1A)" }}>
                          Business Category
                        </span>{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Specify the industry of business
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    id="businessName"
                    label=""
                    placeholder="type something here"
                    value={businessDataDetails.Category}
                    onChange={(e) => {
                      setHasUnsavedChanges(true);
                      setBusinessDataDetails({
                        ...businessDataDetails,
                        Category: e.target.value,
                      });
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ width: 720, height: 40 }}
                  />
                </Box>
              </Box>
              <Box
                className="description-container"
                sx={{ height: "180px !important" }}
              >
                <Box
                  className="inner-container"
                  sx={{ height: "180px !important" }}
                >
                  <Box className="text-container">
                    <Box className="text-group">
                      <Typography variant="subtitle2">
                        <span style={{ color: "#CD3535" }}>*</span>
                        <span style={{ color: "#171D1A)" }}>
                          Business Description
                        </span>{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        In about 140 words, highlight the primary offerings to
                        your customers
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    id="businessName"
                    label=""
                    multiline
                    rows={3}
                    placeholder="type something here"
                    value={businessDataDetails.Description}
                    onChange={(e) => {
                      setHasUnsavedChanges(true);
                      setBusinessDataDetails({
                        ...businessDataDetails,
                        Description: e.target.value,
                      });
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ width: 720, height: 40 }}
                  />
                </Box>
              </Box>
              <Box className="place-container">
                <Box className="inner-container">
                  <Box className="text-container">
                    <Box className="text-group">
                      <Typography variant="subtitle2">
                        <span style={{ color: "#CD3535" }}>*</span>
                        <span style={{ color: "#171D1A)" }}>
                          Where do you serve your customers?
                        </span>{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Indicate if you serve your customers at a physical
                        location or online
                      </Typography>
                    </Box>
                  </Box>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={businessDataDetails.BusinessObjective}
                    onChange={(e) => {
                      setHasUnsavedChanges(true);
                      setBusinessDataDetails({
                        ...businessDataDetails,
                        BusinessObjective: e.target.value,
                      });
                    }}
                  >
                    {["Local", "Online"].map((step, index) => (
                      <FormControlLabel
                        key={index}
                        value={step}
                        control={<Radio />}
                        label={step}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Box>
              <Box className="offers-container">
                <Box className="inner-container">
                  <Box className="text-container">
                    <Box className="text-group">
                      <Typography variant="subtitle2">
                        <span style={{ color: "#CD3535" }}>*</span>
                        <span style={{ color: "#171D1A)" }}>
                          What do you offer your customers?
                        </span>{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Indicate whether your business primarily serves its
                        customers at a physical location or online
                      </Typography>
                    </Box>
                  </Box>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={businessDataDetails.LocationType}
                    onChange={(e) => {
                      setHasUnsavedChanges(true);
                      setBusinessDataDetails({
                        ...businessDataDetails,
                        LocationType: e.target.value,
                      });
                    }}
                  >
                    {["Products", "Services"].map((step, index) => (
                      <FormControlLabel
                        key={index}
                        value={step}
                        control={<Radio />}
                        label={step}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </Box>
              <Box className="business-container">
                <Box className="inner-container">
                  <Box className="text-container">
                    <Box className="text-group">
                      <Typography variant="subtitle2">
                        <span style={{ color: "#CD3535" }}>*</span>
                        <span style={{ color: "#171D1A)" }}>
                          Brand Voice
                        </span>{" "}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Describe the tone and personality of your brand
                      </Typography>
                    </Box>
                  </Box>
                  <TextField
                    id="businessName"
                    label=""
                    placeholder="type something here"
                    value={businessDataDetails.BrandVoice}
                    onChange={(e) => {
                      setHasUnsavedChanges(true);
                      setBusinessDataDetails({
                        ...businessDataDetails,
                        BrandVoice: e.target.value,
                      });
                    }}
                    variant="outlined"
                    size="small"
                    sx={{ width: 720, height: 40 }}
                  />
                </Box>
              </Box>
              {/* Table */}
              <Box className="key-people-container">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table>
                      <TableHead className="main-header">
                        <TableRow className="main-header-inner">
                          <TableCell
                            className="main-header-inner-cell"
                            sx={{ borderBottom: "none !important" }}
                            colSpan={3}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#FFF" }}
                            >
                              Key People
                            </Typography>
                            <Typography variant="body2" sx={{ color: "#FFF" }}>
                              Primary stakeholders in your business
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead className="second-header">
                        <TableRow className="row">
                          <Typography
                            className="header-text"
                            sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                          >
                            Name
                          </Typography>
                        </TableRow>
                        <TableRow className="row">
                          <Typography
                            className="header-text"
                            sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                          >
                            Title
                          </Typography>
                        </TableRow>
                        <TableRow className="row">
                          <Typography
                            className="header-text"
                            sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                          >
                            Expertise{" "}
                          </Typography>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {businessDataDetails?.CustomerPersonas.length > 0 &&
                          businessDataDetails?.CustomerPersonas.map(
                            (row: any, rowIndex: number) => (
                              <TableRow key={rowIndex} className="body-row">
                                {["PersonName", "PersonDescription", "Expertise"].map(
                                  (field, fieldIndex) => (
                                    <TableCell
                                      key={field}
                                      className="row-cell"
                                      onClick={() =>
                                        handleCellClick(rowIndex, field)
                                      }
                                    >
                                      <Box
                                        className="row-inner-cell"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        {editingCell?.row === rowIndex &&
                                        editingCell.field === field ? (
                                          <TextField
                                            value={
                                              row[field as keyof typeof row]
                                            }
                                            onChange={(e) => {
                                              setHasUnsavedChanges(true);
                                              handleInputChange(
                                                e,
                                                rowIndex,
                                                field
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            autoFocus
                                            size="small"
                                            variant="outlined"
                                          />
                                        ) : (
                                          <Typography variant="body2">
                                            {row[field as keyof typeof row]}
                                          </Typography>
                                        )}
                                        {fieldIndex === 2 && ( // Add remove button only in the third column
                                          <IconButton
                                            onClick={() =>
                                              handleRemovePerson(rowIndex)
                                            }
                                          >
                                            <RemoveCircleOutlined
                                              style={{
                                                color: "grey",
                                                padding: "0px !important",
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </Box>
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            )
                          )}

                        {isEditing?.isKeyPeople && (
                          <TableRow className="body-row">
                            {/* Name Input */}
                            <TableCell className="row-cell">
                              <Box className="row-inner-cell">
                                <TextField
                                  label="Name"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={newPeople.PersonName}
                                  onChange={(e) => {
                                    setHasUnsavedChanges(true);
                                    handleInputPeopleChange(
                                      "PersonName",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Box>
                            </TableCell>

                            <TableCell className="row-cell">
                              <Box className="row-inner-cell">
                                <TextField
                                  label="Title"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={newPeople.PersonDescription}
                                  onChange={(e) => {
                                    setHasUnsavedChanges(true);
                                    handleInputPeopleChange(
                                      "PersonDescription",
                                      e.target.value
                                    );
                                  }}
                                />
                              </Box>
                            </TableCell>

                            <TableCell className="row-cell">
                              <Box
                                className="row-inner-cell"
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <TextField
                                  label="Expertise"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "70% !important" }}
                                  value={newPeople.Expertise}
                                  onChange={(e) => {
                                    setHasUnsavedChanges(true);
                                    handleInputPeopleChange(
                                      "Expertise",
                                      e.target.value
                                    );
                                  }}
                                />
                                <Box>
                                  <IconButton
                                    onClick={() => {
                                      if (
                                        newPeople.PersonName &&
                                        newPeople.PersonDescription &&
                                        newPeople.Expertise
                                      ) {
                                        setBusinessDataDetails({
                                          ...businessDataDetails,
                                          CustomerPersonas: [
                                            ...businessDataDetails.CustomerPersonas,
                                            newPeople,
                                          ],
                                        });
                                        setIsEditing({
                                          ...isEditing,
                                          isKeyPeople: false,
                                        });
                                        setNewPeople({
                                          PersonName: "",
                                          PersonDescription: "",
                                          Expertise: "",
                                        });
                                      } else {
                                        CustomToast(
                                          "Please fill all fields correctly",
                                          "error"
                                        );
                                      }
                                    }}
                                  >
                                    <DoneIcon style={{ color: "green" }} />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => {
                                      setIsEditing({
                                        ...isEditing,
                                        isKeyPeople: false,
                                      });
                                      setNewPeople({
                                        PersonName: "",
                                        PersonDescription: "",
                                        Expertise: "",
                                      });
                                    }}
                                  >
                                    <CloseIcon style={{ color: "red" }} />
                                  </IconButton>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow className="footer-button">
                          <TableCell colSpan={3}>
                            <Button
                              size="medium"
                              color="primary"
                              variant="text"
                              startIcon={<AddFilled />}
                              onClick={() =>
                                setIsEditing({
                                  ...isEditing,
                                  isKeyPeople: true,
                                })
                              }
                            >
                              Add Person
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>

              <Box className="competitors-container">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table>
                      <TableHead className="main-header">
                        <TableRow className="main-header-inner">
                          <TableCell
                            className="main-header-inner-cell"
                            sx={{ borderBottom: "none !important" }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#FFF" }}
                            >
                              Competitors
                            </Typography>
                            <Typography variant="body2" sx={{ color: "#FFF" }}>
                              The websites of businesses that have similar
                              offerings
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {businessDataDetails?.Competitors.length > 0 &&
                          businessDataDetails?.Competitors.map(
                            (row: any, rowIndex: number) => (
                              <TableRow key={rowIndex} className="body-row">
                                <TableCell
                                  className="row-cell"
                                  onClick={() =>
                                    handleCellClick(rowIndex, "Website")
                                  }
                                >
                                  <Box className="row-inner-cell">
                                    {editingCell?.row === rowIndex &&
                                    editingCell.field === "Website" ? (
                                      <TextField
                                        value={row.Url}
                                        onChange={(e) => {
                                          setHasUnsavedChanges(true);
                                          handleInputChangeCompetitor(
                                            e,
                                            rowIndex,
                                            "Website"
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        onKeyDown={handleKeyDown}
                                        autoFocus
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                      />
                                    ) : (
                                      <Typography variant="body2">
                                        {row.Website}
                                      </Typography>
                                    )}
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveCompetitor(rowIndex)
                                    }
                                  >
                                    <RemoveCircleOutlined
                                      style={{ color: "grey" }}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        {isEditing?.isUrl && (
                          <TableRow className="body-row">
                            <TableCell className="row-cell">
                              <TextField
                                label="Competitor URL"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={newCompetitor.Website}
                                onChange={(e) => {
                                  setHasUnsavedChanges(true);
                                  handleInputCompetitorChange(
                                    "Website",
                                    e.target.value
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  if (newCompetitor.Website) {
                                    setBusinessDataDetails({
                                      ...businessDataDetails,
                                      Competitors: [
                                        ...businessDataDetails.Competitors,
                                        newCompetitor,
                                      ],
                                    });
                                    setIsEditing({
                                      ...isEditing,
                                      isUrl: false,
                                    });
                                    setNewCompetitor({ Website: "" });
                                  } else {
                                    CustomToast(
                                      "Please enter a valid URL",
                                      "error"
                                    );
                                  }
                                }}
                              >
                                <DoneIcon style={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setIsEditing({
                                    ...isEditing,
                                    isUrl: false,
                                  });
                                  setNewCompetitor({ Website: "" });
                                }}
                              >
                                <CloseIcon style={{ color: "red" }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow className="footer-button">
                          <TableCell colSpan={2}>
                            <Button
                              size="medium"
                              color="primary"
                              variant="text"
                              startIcon={<AddFilled />}
                              onClick={() =>
                                setIsEditing({
                                  ...isEditing,
                                  isUrl: true,
                                })
                              }
                            >
                              Add URL
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>

              <Box className="location-container">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table>
                      <TableHead className="main-header">
                        <TableRow className="main-header-inner">
                          <TableCell
                            className="main-header-inner-cell"
                            sx={{ borderBottom: "none !important" }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ color: "#FFF" }}
                            >
                              Locations & Addresses
                            </Typography>
                            <Typography variant="body2" sx={{ color: "#FFF" }}>
                              Provide all the addresses from which your business
                              operates
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead className="second-header">
                        {["Name", "Address", "TimeZone", "Integrations"].map(
                          (col) => (
                            <TableRow className="row">
                              {" "}
                              <Typography
                                className="header-text"
                                sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                              >
                                {col}{" "}
                              </Typography>{" "}
                            </TableRow>
                          )
                        )}
                      </TableHead>
                      <TableBody>
                        {businessDataDetails.Locations.length > 0 &&
                          businessDataDetails.Locations.map(
                            (row: any, rowIndex: number) => (
                              <TableRow key={rowIndex} className="body-row">
                                {[
                                  "Name",
                                  "Address1",
                                  "TimeZone",
                                  "Integrations",
                                ].map((field, colIndex, array) => (
                                  <TableCell
                                    key={field}
                                    className="row-cell"
                                    onClick={() =>
                                      handleCellClick(rowIndex, field)
                                    }
                                  >
                                    <Box
                                      className="row-inner-cell"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      {editingCell?.row === rowIndex &&
                                      editingCell.field === field ? (
                                        field === "TimeZone" ? (
                                          <Select
                                            value={row[field]}
                                            onChange={(e) => {
                                              setHasUnsavedChanges(true);
                                              handleInputChangeLocation(
                                                e,
                                                rowIndex,
                                                field
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            autoFocus
                                            fullWidth
                                            size="small"
                                          >
                                            {timezones.map((tz) => (
                                              <MenuItem key={tz} value={tz}>
                                                {tz}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        ) : (
                                          <TextField
                                            value={
                                              row[field as keyof typeof row]
                                            }
                                            onChange={(e) => {
                                              setHasUnsavedChanges(true);
                                              handleInputChange(
                                                e,
                                                rowIndex,
                                                field
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown}
                                            autoFocus
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                          />
                                        )
                                      ) : (
                                        <Typography variant="body2">
                                          {row[field as keyof typeof row]}
                                        </Typography>
                                      )}

                                      {/* Show Remove Icon only in the last column */}
                                      {field === array[array.length - 1] && (
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveLocation(rowIndex)
                                          }
                                          sx={{ padding: "0px !important" }}
                                        >
                                          <RemoveCircleOutlined
                                            style={{ color: "grey" }}
                                          />
                                        </IconButton>
                                      )}
                                    </Box>
                                  </TableCell>
                                ))}
                              </TableRow>
                            )
                          )}

                        {isEditing?.isLocation && (
                          <TableRow className="body-row">
                            <TableCell className="row-cell">
                              <Box className="row-inner-cell">
                                <TextField
                                  label="Name"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={newLocation.Name}
                                  onChange={(e) => {
                                    setHasUnsavedChanges(true);
                                    setNewLocation({
                                      ...newLocation,
                                      Name: e.target.value,
                                    });
                                  }}
                                />
                              </Box>
                            </TableCell>
                            <TableCell className="row-cell">
                              <Box className="row-inner-cell">
                                <TextField
                                  label="Address"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={newLocation.Address1}
                                  onChange={(e) => {
                                    setHasUnsavedChanges(true);
                                    setNewLocation({
                                      ...newLocation,
                                      Address1: e.target.value,
                                    });
                                  }}
                                />
                              </Box>
                            </TableCell>
                            <TableCell className="row-cell">
                              <Box className="row-inner-cell">
                                <Select
                                  value={newLocation.TimeZone}
                                  onChange={(e) => {
                                    setHasUnsavedChanges(true);
                                    setNewLocation({
                                      ...newLocation,
                                      TimeZone: e.target.value as string,
                                    });
                                  }}
                                  displayEmpty
                                  fullWidth
                                  size="small"
                                >
                                  <MenuItem value="" disabled>
                                    Select Timezone
                                  </MenuItem>
                                  {timezones.map((tz) => (
                                    <MenuItem key={tz} value={tz}>
                                      {tz}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                            </TableCell>
                            <TableCell className="row-cell">
                              <Box
                                className="row-inner-cell"
                                sx={{
                                  display: "flex !important",
                                  flexDirection: "row !important",
                                  justifyContent: "space-between !important",
                                }}
                              >
                                <TextField
                                  label="Integrations"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "60% !important" }}
                                  value={newLocation.Integrations}
                                  onChange={(e) => {
                                    setHasUnsavedChanges(true);
                                    setNewLocation({
                                      ...newLocation,
                                      Integrations: e.target.value,
                                    });
                                  }}
                                />
                                <Box>
                                  <IconButton
                                    onClick={() => {
                                      if (
                                        newLocation.Name &&
                                        newLocation.Address1 &&
                                        newLocation.TimeZone &&
                                        newLocation.Integrations
                                      ) {
                                        setBusinessDataDetails({
                                          ...businessDataDetails,
                                          Locations: [
                                            ...businessDataDetails.Locations,
                                            newLocation,
                                          ],
                                        });
                                        setIsEditing({
                                          ...isEditing,
                                          isLocation: false,
                                        });
                                        setNewLocation({
                                          Name: "",
                                          Address1: "",
                                          TimeZone: "",
                                          Integrations: "",
                                        });
                                      } else {
                                        CustomToast(
                                          "Please fill all fields correctly",
                                          "error"
                                        );
                                      }
                                    }}
                                  >
                                    <DoneIcon style={{ color: "green" }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      setIsEditing({
                                        ...isEditing,
                                        isLocation: false,
                                      })
                                    }
                                  >
                                    <CloseIcon style={{ color: "red" }} />
                                  </IconButton>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow className="footer-button">
                          <TableCell colSpan={5}>
                            <Button
                              size="medium"
                              color="primary"
                              variant="text"
                              startIcon={<AddFilled />}
                              onClick={() =>
                                setIsEditing({ ...isEditing, isLocation: true })
                              }
                            >
                              Add Location
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </>
          )}
          {selectedTab === 1 && <ProductsTable />}
        </Box>
      </Loader>
    </Box>
  );
};

export default NewBusinessProfile;
