import "./AddCampaign.scss";

import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DrawerComponent from "./DrawerComponent";
import GetAppIcon from "@mui/icons-material/GetApp";
import Loader from "../../utils/loader/Loader";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import useImageUpload from "../../custom_hooks/useImageUpload";
import { useSelector } from "react-redux";

type TransformedObject = {
  Name: string;
  Link: string;
};

const ReviewsAddCampaign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = useSelector((state: any) => state.auth);
  const { uploadImage, loading: loadingForm } = useImageUpload({
    id: userDetails.id,
  });
  const [loading, setLoading] = useState(loadingForm);
  const data = location.state;
  const [campaignData, setcampaignData] = useState<TransformedObject>({
    Name: "",
    Link: "",
  });
  const [popUpData, setPopUpData] = useState<any>({});
  const [qrCodeGenerated, setQrCodeGenerated] = useState(false);
  const { doApi } = useApi();
  // Fetch Campaign activites
  const createCampaign = async () => {
    if (
      campaignData?.Name !== "" &&
      campaignData?.Link !== "" &&
      Array.isArray(popUpData?.Recipients) &&
      popUpData?.Recipients.length > 0
    ) {
      setLoading(true);
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      const fileName = `qr_code_${campaignData?.Name}_${timestamp}.png`;
      const imageURL = await uploadImage(
        popUpData?.QrCodeBase64 || "",
        fileName
      );
      let payload = {
        userUniqueId: data.usrUniqueId,
        businessId: data.businessId,
        name: campaignData?.Name,
        locationId: data.locationId,
        mode: "QRCODE",
        campaignId: "",
        activities: [],
        noOfActivities: 0,
        status: "scheduled",
        recipients: popUpData?.Recipients,
        reviewLink: campaignData?.Link,
        qrCodeType: popUpData?.QrCodeType,
        qrCodePrintMsg: popUpData?.QrCodePrintMsg,
        qrCodeMailMsg: popUpData?.QrCodeMailMsg,
        genQRCode: imageURL,
      };
      try {
        let campaignConfig: IAPIConfig = {
          URL: `/Campaign/CreateCampaign?userUniqueId=${data.usrUniqueId}`,
          method: "POST",
          payLoad: payload,
        };

        const campaignRes: any = await doApi(campaignConfig, "seedcore");
        if (campaignRes?.data.err === false) {
          CustomToast(campaignRes.data.message, "success");
          setcampaignData({ Link: "", Name: "" });
          navigate(-1);
          return true;
        } else {
          CustomToast(campaignRes.data.message, "error");
          return false;
        }
      } catch (error: any) {
        CustomToast(error?.message, "error");
        return false;
      } finally {
        setLoading(false);
      }
    } else {
       CustomToast("Please enter campaign name or url", "error");
    }
  };
  const handleBack = async () => {
    // const res = await createCampaign();
    // if (res) {
    //   navigate(-1);
    // }
    navigate(-1);
  };

  const [Draweropen, setDrawerOpen] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const handleGenerateQRCode = () => {
    if (campaignData.Link) {
      setQrCodeGenerated(true);
    }
  };

  const handleDownloadPDF = async () => {
    const qrCodeElement = document.getElementById("qr-code");

    if (!qrCodeElement) return;

    const canvas = await html2canvas(qrCodeElement);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 15, 40, 300, 50);
    pdf.save(`${campaignData.Name || "QR_Code_Campaign"}.pdf`);
  };

  return (
    <Box className="Add-Campaign-Container">
      <Box>
        <ArrowBackIcon
          sx={{
            marginBottom: "12px",
            color: "rgba(0, 0, 0, 0.56)",
            cursor: "pointer",
          }}
          onClick={() => handleBack()}
        />
        {hasUnsavedChanges && (
          <Alert variant="standard" severity="warning">
            You have unsaved edits
          </Alert>
        )}
      </Box>

      <Loader spinning={loading}>
        <Box className="Campaign-info">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5" sx={{ marginTop: 3 }}>
                New QR Code Campaign
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: 3, marginBottom: 2 }}
              >
                Campaign Info
              </Typography>
            </Box>
            <Box sx={{ display: "flex", columnGap: "12px" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={createCampaign}
              >
                Save Edits
              </Button>
              {hasUnsavedChanges && (
                <Button
                  variant="outlined"
                  color="error"
                  size="medium"
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate("/reviews");
                  }}
                >
                  Exit Without saving
                </Button>
              )}
            </Box>
          </Box>

          <Box sx={{ width: { xs: "100%", lg: "768px" } }}>
            {/* Campaign Name */}
            <TextField
              label="Campaign Name"
              variant="filled"
              fullWidth
              value={campaignData?.Name}
              onChange={(e) => {
                setHasUnsavedChanges(true);
                setcampaignData({ ...campaignData, Name: e.target.value });
              }}
              sx={{ marginBottom: 2 }}
            />

            <TextField
              label="Review Link"
              helperText="Link where you want customers to leave reviews"
              variant="filled"
              fullWidth
              value={campaignData?.Link}
              onChange={(e) => {
                setHasUnsavedChanges(true);
                setcampaignData({ ...campaignData, Link: e.target.value });
              }}
            />
          </Box>

          <Box sx={{ marginTop: 4 }}>
            <Button
              variant="contained"
              onClick={handleGenerateQRCode}
              style={{ display: !qrCodeGenerated ? "block" : "none" }}
            >
              Generate QR Code
            </Button>

            {qrCodeGenerated && campaignData.Link && (
              <Box sx={{ marginTop: 4 }}>
                <div id="qr-code">
                  <QRCodeCanvas
                    value={campaignData.Link}
                    size={160}
                    includeMargin
                  />
                </div>
                <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
                  <Button variant="outlined" onClick={toggleDrawer(true)}>
                    Send Email
                  </Button>
                  <IconButton aria-label="download" onClick={handleDownloadPDF}>
                    <GetAppIcon
                      sx={{ width: "24px", height: "24px", color: "#0F4343" }}
                    />
                  </IconButton>
                </Stack>
              </Box>
            )}
          </Box>

          <DrawerComponent
            open={Draweropen}
            toggleDrawer={toggleDrawer}
            reviewLink={campaignData.Link}
            setPopUpData={setPopUpData}
          />
        </Box>
      </Loader>
    </Box>
  );
};

export default ReviewsAddCampaign;
