import "./LoginHomePage.scss";

import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";

import CustomAreaChartComponent from "../../components/CustomCharts/AreaCharts/CustomAreaCharts";
import { GoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import StatsCard from "../businessDashboard/StatsCards";

const Homepage = ({ handleSuccess, handleFailure }: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const data = [
    {
      text1: "Focus",
      text2: "on the",
      highlight: "10%",
      description:
        "Focus on what matters by identifying the small fraction of marketing efforts that produce the biggest impact.",
    },
    {
      text1: "Automate",
      text2: "",
      highlight: "everything",
      description:
        "Automate mundane tasks like review generation, content creation, and performance tracking.",
    },
    {
      text1: "Real",
      text2: "",
      highlight: "search data",
      description:
        "Real search and competitor data power every recommendation, ensuring data-driven decisions and measurable results.",
    },
    {
      text1: "Built",
      text2: "for",
      highlight: "agencies",
      description: "Purpose-built for agencies helping local businesses.",
    },
  ];

  const [tabs, setTabs] = useState(["CLK", "IMP", "CTR", "POS"]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleViewOrientation = (
    _event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabs(
      (prevTabs) =>
        prevTabs.includes(newValue)
          ? prevTabs.filter((tab) => tab !== newValue) // Deselect if already selected
          : [...prevTabs, newValue] // Select if not already selected
    );
  };

  return (
    <>
      <Box className="tab-container">
        {/* Hamburger Menu Icon */}
        <IconButton className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
          <MenuIcon />
        </IconButton>

        {/* Tabs - Shown/Hidden based on isOpen state */}
        <Box className={`tabs-wrapper ${isOpen ? "open" : ""}`}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              "& .MuiTabs-indicator": { backgroundColor: "white" },
            }}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            <Tab label="Home" className="tab" />
            {/* <Tab label="What We Offer" className="tab" />
                 <Tab label="Why Us" className="tab" /> */}
          </Tabs>
        </Box>
        {/* <Button startIcon={<GoogleIcon />} className="google-button">
          <Typography variant="h6">Login</Typography>
        </Button> */}
        <GoogleLogin onSuccess={handleSuccess} onError={handleFailure} />
      </Box>
      <Box className="homepage-login">
        {value === 0 && (
          <>
            {/* Full Content in First Tab */}
            <Box
              className="frame-202"
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Box className="right-frame">
                <Box className="frame-201">
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="medium"
                    sx={{
                      color: "white !important",
                      borderColor: "white !important",
                      mb: 2,
                    }}
                  >
                    Hire Massic's AI SEO Agent
                  </Button>
                  <Typography
                    variant={isMobile ? "h5" : "h1"}
                    sx={{ color: "white" }}
                  >
                    Grow local businesses <br />
                    <Typography
                      component="span"
                      variant={isMobile ? "h5" : "h1"}
                      sx={{ textAlign: "center !important" }}
                    >
                      faster with{"  "}
                    </Typography>
                    <Typography
                      variant={isMobile ? "h5" : "h1"}
                      component="span"
                      sx={{ color: "#69F0AE" }}
                    >
                      Massic.
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center !important",
                      width: "708px",
                      color: "white",
                      "@media (max-width: 768px)": {
                        fontSize: "16px",
                        width: "90%",
                      },
                    }}
                  >
                    We uncover the 10% of hidden, high-impact opportunities that
                    drive 90% of your marketing results—and help you execute
                    them automatically.
                  </Typography>
                  <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleFailure}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              className="google-search-console"
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: 1056,
                height: 673,
                mt: 52,
                marginBottom: "108px",
              }}
            >
              <Card className="review-info-card">
                <Box>
                  <Typography variant="h5">Organic Search</Typography>
                  <Typography variant="body2" marginTop={1}>
                    Source: Google Search Console
                  </Typography>
                </Box>

                <Box>
                  <ToggleButtonGroup
                    value={tabs}
                    className="toggleButtonGroup"
                    exclusive
                    onChange={handleViewOrientation}
                    sx={{ height: "40px", color: "#fff" }}
                  >
                    <ToggleButton
                      disableRipple
                      className="toggleButton"
                      value="CLK"
                    >
                      CLK
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      className="toggleButton"
                      value="IMP"
                    >
                      IMP
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      className="toggleButton"
                      value="CTR"
                    >
                      CTR
                    </ToggleButton>
                    <ToggleButton
                      disableRipple
                      className="toggleButton"
                      value="POS"
                    >
                      POS
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Card>

              <Box sx={{ marginTop: 1, opacity: 0.6 }}>
                <CustomAreaChartComponent
                  key={JSON.stringify([
                    {
                      name: "CLK",
                      data: [
                        11, 20, 29, 11, 11, 5, 5, 13, 17, 38, 9, 22, 14, 9, 4,
                        17, 19, 10, 16, 4, 10, 5, 22, 21, 13, 17, 4,
                      ],
                    },
                    {
                      name: "IMP",
                      data: [
                        764, 897, 794, 868, 732, 683, 639, 706, 796, 857, 642,
                        631, 845, 717, 829, 844, 908, 780, 839, 742, 762, 727,
                        1000, 920, 895, 813, 892,
                      ],
                    },
                  ])}
                  chartType="area"
                  colors={[
                    { clk: "#007bff" },
                    { imp: "#6f42c1" },
                    { ctr: "#00897B" },
                    { pos: "#fd7e14" },
                  ]}
                  categories={[
                    "2025-01-05",
                    "2025-01-06",
                    "2025-01-07",
                    "2025-01-08",
                    "2025-01-09",
                    "2025-01-10",
                    "2025-01-11",
                    "2025-01-12",
                    "2025-01-13",
                    "2025-01-14",
                    "2025-01-15",
                    "2025-01-16",
                    "2025-01-17",
                    "2025-01-18",
                    "2025-01-19",
                    "2025-01-20",
                    "2025-01-21",
                    "2025-01-22",
                    "2025-01-23",
                    "2025-01-24",
                    "2025-01-25",
                    "2025-01-26",
                    "2025-01-27",
                    "2025-01-28",
                    "2025-01-29",
                    "2025-01-30",
                    "2025-01-31",
                  ]}
                  overViews={{
                    Clk: { Total: "376", Diff: "52%", Trend: "up" },
                    Imp: { Total: "21.5K", Diff: "9%", Trend: "down" },
                    Ctr: { Total: "0.5%", Diff: "57%", Trend: "up" },
                    Pos: { Total: "34.8", Diff: "8.33", Trend: "down" },
                  }}
                  seriesData={[
                    {
                      name: "CLK",
                      data: [
                        11, 20, 29, 11, 11, 5, 5, 13, 17, 38, 9, 22, 14, 9, 4,
                        17, 19, 10, 16, 4, 10, 5, 22, 21, 13, 17, 4,
                      ],
                    },
                    {
                      name: "IMP",
                      data: [
                        764, 897, 794, 868, 732, 683, 639, 706, 796, 857, 642,
                        631, 845, 717, 829, 844, 908, 780, 839, 742, 762, 727,
                        1000, 920, 895, 813, 892,
                      ],
                    },
                    {
                      name: "CTR",
                      data: [
                        1.43979057591623, 2.22965440356745, 3.65239294710327,
                        1.26728110599078, 1.50273224043716, 0.732064421669107,
                        0.782472613458529, 1.8413597733711, 2.1356783919598,
                        4.4340723453909, 1.4018691588785, 3.486529318542,
                        1.65680473372781, 1.25523012552301, 0.482509047044632,
                        2.01421800947867, 2.09251101321586, 1.28205128205128,
                        1.90703218116806, 0.539083557951482, 1.31233595800525,
                        0.687757909215956, 2.2, 2.28260869565217,
                        1.45251396648045, 2.0910209102091, 0.448430493273543,
                      ],
                    },
                    {
                      name: "POS",
                      data: [
                        38.1, 39.2, 32.7, 35, 33.1, 37.6, 37.5, 32, 33.5, 30.5,
                        30.6, 27.1, 36.4, 40.6, 39.9, 37.4, 38.1, 36.2, 34,
                        35.3, 37.8, 36.2, 32.1, 31.7, 32.9, 31.3, 33,
                      ],
                    },
                  ]}
                  title="Custom Chart Title"
                />
              </Box>

              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1.4 }}
                sx={{
                  display: "flex",
                  marginTop: "0.2rem",
                  gap: "0.5rem",
                  marginLeft: "0px !important",
                  opacity: 0.9,
                }}
              >
                <Grid columnSpacing={{ xs: 12, lg: 4 }} sx={{ flexGrow: 1 }}>
                  <StatsCard
                    title="Branded"
                    description={`vs prev. 28 days`}
                    value={"42%"}
                    percentage={"147%"}
                    increase={true}
                    message="branded in settings to activate this widget."
                    con={false}
                  />
                </Grid>
                <Grid columnSpacing={{ xs: 12, lg: 4 }} sx={{ flexGrow: 1 }}>
                  <StatsCard
                    title="Product"
                    description={`vs prev. 28 days`}
                    value="69%"
                    percentage="20%"
                    increase={false}
                    message="products in settings to activate this widget."
                    con={false}
                  />
                </Grid>
                <Grid columnSpacing={{ xs: 12, lg: 4 }} sx={{ flexGrow: 1 }}>
                  <StatsCard
                    title="In Top 10"
                    description={`vs prev. 28 days`}
                    value={"7"}
                    percentage={"147%"}
                    increase={true}
                    message="intop10 in settings to activate this widget."
                    con={false}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="frame-218">
              <Box className="frame-203">
                <Typography
                  variant="h3"
                  color="#FFF"
                  sx={{
                    textAlign: "center",
                    fontFeatureSettings: "'liga' off, 'clig' off ",
                  }}
                >
                  Stop wasting time and budget on guesswork.
                </Typography>
                <Typography variant="body1" color="white" align="center">
                  Massic pinpoints exactly what moves the needle for your local
                  business clients and automates the rest.
                </Typography>
              </Box>
              <Box className="frame-213">
                <Box className="frame-209">
                  <Box className="frame-235">
                    <Typography
                      variant="h5"
                      sx={{
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        color: "#69f0ae",
                      }}
                    >
                      Generate Reviews
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      color: "#FFF",
                    }}
                  >
                    Automatically request and respond to reviews with AI, gain
                    real-time insights into customer sentiment, benchmark
                    performance against competitors, and get actionable
                    recommendations to elevate your reputation.
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box display="flex" gap={1}>
                      <Chip
                        label="Review Generation Campaigns"
                        variant="outlined"
                        sx={{
                          borderColor: "primary.contrastText",
                          color: "primary.contrastText",
                        }}
                      />
                      <Chip
                        label="AI Replies"
                        variant="outlined"
                        sx={{
                          borderColor: "primary.contrastText",
                          color: "primary.contrastText",
                        }}
                      />
                    </Box>
                    <Chip
                      label="Business & Competitor Insights"
                      variant="outlined"
                      sx={{
                        borderColor: "primary.contrastText",
                        color: "primary.contrastText",
                        width: "max-content",
                      }}
                    />
                  </Box>
                </Box>
                <Box className="frame-215">
                  <Box className="frame-235">
                    <Typography
                      variant="h5"
                      sx={{
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        color: "#69f0ae",
                      }}
                    >
                      Create Optimized Content
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      color: "#FFF",
                    }}
                  >
                    Use niche-specific search data to uncover thousands of
                    targeted keywords for hyper-local campaigns. With one-click
                    blog creation and data-driven optimization, attract and
                    convert the right audience.
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box display="flex" gap={1}>
                      <Chip
                        label="Keyword & Topic Research"
                        variant="outlined"
                        sx={{
                          borderColor: "primary.contrastText",
                          color: "primary.contrastText",
                        }}
                      />
                      <Chip
                        label="One-Click Blogs"
                        variant="outlined"
                        sx={{
                          borderColor: "primary.contrastText",
                          color: "primary.contrastText",
                        }}
                      />
                    </Box>
                    <Chip
                      label="Data-Driven Optimizations"
                      variant="outlined"
                      sx={{
                        borderColor: "primary.contrastText",
                        color: "primary.contrastText",
                        width: "max-content",
                      }}
                    />
                  </Box>
                </Box>
                <Box className="frame-218">
                  <Box className="frame-235">
                    <Typography
                      variant="h5"
                      sx={{
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        color: "#69f0ae",
                      }}
                    >
                      Gain Actionable Insights
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      color: "#FFF",
                    }}
                  >
                    Use niche-specific search data to uncover thousands of
                    targeted keywords for hyper-local campaigns. With one-click
                    blog creation and data-driven optimization, attract and
                    convert the right audience.
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box display="flex" gap={1}>
                      <Chip
                        label="Keyword & Topic Research"
                        variant="outlined"
                        sx={{
                          borderColor: "primary.contrastText",
                          color: "primary.contrastText",
                        }}
                      />
                      <Chip
                        label="One-Click Blogs"
                        variant="outlined"
                        sx={{
                          borderColor: "primary.contrastText",
                          color: "primary.contrastText",
                        }}
                      />
                    </Box>
                    <Chip
                      label="Data-Driven Optimizations"
                      variant="outlined"
                      sx={{
                        borderColor: "primary.contrastText",
                        color: "primary.contrastText",
                        width: "max-content",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Grid Section */}
            <Box className="frame-246">
              {data.map((item, index) => (
                <Box
                  key={index}
                  className="frame-246-1"
                  sx={{
                    borderBottom:
                      index !== data.length - 1
                        ? "0.5px solid rgba(255, 255, 255, 0.2)"
                        : "none",
                    paddingBottom: "32px",
                  }}
                >
                  {/* Main Text Section */}
                  <Box className="frame-247">
                    <Box component="span" className="text1">
                      {item.text1}{" "}
                      <Box component="span" className="text2">
                        {item.text2}{" "}
                      </Box>
                      <Box component="span" className="highlight">
                        {item.highlight}
                      </Box>
                    </Box>
                  </Box>

                  {/* Description */}
                  <Typography
                    className="description"
                    sx={{ color: "white", marginTop: "8px", textAlign: "left" }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* Call to Action */}
            <Box className="frame-237">
              <Box className="frame-227">
                <Typography className="text">
                  Unlock the 10% that drives 90% of your growth.
                </Typography>
                <GoogleLogin
                  onSuccess={handleSuccess}
                  onError={handleFailure}
                />

                {/* <Button
                variant="contained"
                startIcon={
                  <GoogleIcon
                    style={{
                      width: 26,
                      height: 26,
                      color: "rgb(15, 67, 67)",
                    }}
                  />
                }
                className="get-started-button"
              >
                <Typography className="btn-text">Get Started</Typography>
              </Button> */}
              </Box>
              <Box className="frame-229">
                <Box className="frame-231">
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#338484",
                    }}
                  >
                    massic
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "rgba(51, 132, 132, 0.30)",
                      textAlign: "center",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                    }}
                  >
                    Operated by Kanahiku LLC
                  </Typography>
                </Box>
                <Box className="frame-230">
                  <Box className="frame-228">
                    <Link to="/terms">
                      <Typography
                        sx={{
                          color: "var(--primary-contrastText, #FFF)",
                          textAlign: "center",
                          fontFeatureSettings: "'liga' off, 'clig' off",
                          fontFamily:
                            "var(--fontFamily, 'IBM Plex Sans') !important",
                          fontSize: "var(--font-size-125-rem, 20px) !important",
                          fontStyle: "normal",
                          fontWeight:
                            "var(--fontWeightRegular, 400) !important",
                          lineHeight: "160%", // 32px
                          letterSpacing: "0.15px",
                        }}
                      >
                        Terms of service
                      </Typography>
                    </Link>

                    <Typography sx={{ color: "white", fontSize: "20px" }}>
                      |
                    </Typography>

                    <Link to="/privacy">
                      <Typography
                        sx={{
                          color: "var(--primary-contrastText, #FFF)",
                          textAlign: "center",
                          fontFeatureSettings: "'liga' off, 'clig' off",
                          fontFamily:
                            "var(--fontFamily, 'IBM Plex Sans') !important",
                          fontSize: "var(--font-size-125-rem, 20px) !important",
                          fontStyle: "normal",
                          fontWeight:
                            "var(--fontWeightRegular, 400) !important",
                          lineHeight: "160%", // 32px
                          letterSpacing: "0.15px",
                        }}
                      >
                        Privacy Policy
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}

        {/* Default Content for Other Tabs */}
        {value === 1 && (
          <Container maxWidth="lg" sx={{ textAlign: "center", py: 8 }}>
            <Typography variant="h3" sx={{ color: "white", mb: 2 }}>
              What We Offer
            </Typography>
            <Typography variant="body1" sx={{ color: "white" }}>
              Explore our amazing services tailored for your business growth.
            </Typography>
          </Container>
        )}

        {value === 2 && (
          <Container maxWidth="lg" sx={{ textAlign: "center", py: 8 }}>
            <Typography variant="h3" sx={{ color: "white", mb: 2 }}>
              Why Us
            </Typography>
            <Typography variant="body1" sx={{ color: "white" }}>
              Find out why businesses trust Massic for their marketing needs.
            </Typography>
          </Container>
        )}
      </Box>
    </>
  );
};

export default Homepage;
