/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import countries from "../countries.json";

type Country = {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
};

const CountryAutocomplete: React.FC<{
  onCountrySelect: (country: Country | null) => void;
}> = ({ onCountrySelect }) => {
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    countries.find((country) => country.code === "US") || null
  );

  useEffect(() => {
    if (selectedCountry) {
      onCountrySelect(selectedCountry);
    }
  }, [selectedCountry]);

  return (
    <Autocomplete
      options={countries}
      value={selectedCountry}
      getOptionLabel={(option) => `(${option.dial_code}) ${option.name}`}
      renderOption={(props, option) => (
        <li {...props} style={{ whiteSpace: "nowrap", fontSize: "12px" }}>
          ({option.dial_code}) {option.name}
        </li>
      )}
      onChange={(_, value) => {
        setSelectedCountry(value);
        onCountrySelect(value); // Ensure the selected country is passed immediately
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          variant="outlined"
          sx={{
            height: "40px",
            fontSize: "14px",
            "& .MuiInputBase-root": {
              height: "40px",
              fontSize: "14px",
            },
          }}
        />
      )}
      sx={{
        "& .MuiAutocomplete-listbox": {
          maxWidth: "none",
          minWidth: "50px",
          fontSize: "14px",
        },
        "& .MuiAutocomplete-inputRoot": {
          minWidth: "150px",
          fontSize: "14px",
        },
      }}
    />
  );
};

export default CountryAutocomplete;
