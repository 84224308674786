/* eslint-disable react-hooks/exhaustive-deps */

import "./IntentClusters.scss";

import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

interface ClusterData {
  clusterName: string;
  searchVolume: number;
  keywords: string[] | string;
  keywordsCount: number;
}

interface intentCluster {
  topicName: string;
  searchVolume: number;
  clusters: string[];
  keywords: string[];
}

interface IntentClustersProps {
  data?: ClusterData[];
  tab: number;
  setAlgorithmProp?: (algorithm: string) => void;
  setOverlapProp?: (overlap: string) => void;
  setTightnessProp?: (tightness: string) => void;
  OnSubmitFunc: () => void;
  dataRes?: intentCluster[];
}
const IntentClusters: React.FC<IntentClustersProps> = ({
  data = [],
  tab,
  setAlgorithmProp,
  setOverlapProp,
  setTightnessProp,
  OnSubmitFunc,
  dataRes = [],
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [algorithm, setAlgorithm] = useState("");
  const [overlap, setOverlap] = useState("");
  const [tightness, setTightness] = useState("");
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [expandedClusterRow, setExpandedClusterRow] = useState<number | null>(
    null
  );

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (setAlgorithmProp) setAlgorithmProp(algorithm);
  }, [algorithm]);

  useEffect(() => {
    if (setOverlapProp) setOverlapProp(overlap);
  }, [overlap]);

  useEffect(() => {
    if (setTightnessProp) setTightnessProp(tightness);
  }, [tightness]);

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const toggleExpand = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const toggleClusterExpand = (index: number) => {
    setExpandedClusterRow(expandedClusterRow === index ? null : index);
  };

  return (
    <>
      <Card className="fourthtab-card-container">
        <Box className="card-header">
          <Typography variant="h5" className="card-header-inner">
            {tab === 3 ? "Intent Clusters" : "Total Clusters"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "var(--text-secondary, rgba(0, 0, 0, 0.60))",
              opacity: 0.6,
              fontFeatureSettings: "'liga' off, 'clig' off",
            }}
          >
            {tab === 3 ? data.length : dataRes.length} total clusters
          </Typography>
        </Box>
        <Box className="card-controls">
          <Box className="inner-controls">
            {tab === 3 && (
              <>
                <FormControl sx={{ width: 180 }}>
                  <InputLabel id="algorithm-select-label">Algorithm</InputLabel>
                  <Select
                    labelId="algorithm-select-label"
                    id="algorithm-select"
                    value={algorithm}
                    label="Age"
                    onChange={(e) => setAlgorithm(e.target.value)}
                  >
                    <MenuItem value="Centroid">Centroid</MenuItem>
                    <MenuItem value="Agglomerative">Agglomerative</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: 180 }}>
                  <InputLabel id="overlap-select-label">Overlap</InputLabel>
                  <Select
                    labelId="overlap-select-label"
                    id="overlap-select"
                    value={overlap}
                    label="overlap"
                    onChange={(e) => setOverlap(e.target.value)}
                  >
                    {[...Array(10)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            {tab === 4 && (
              <FormControl sx={{ width: 180 }}>
                <InputLabel id="tightness-select-label">Tightness</InputLabel>
                <Select
                  labelId="tightness-select-label"
                  id="tightness-select"
                  value={tightness}
                  label="tightness"
                  onChange={(e) => setTightness(e.target.value)}
                >
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={OnSubmitFunc}
            disabled={tab === 3 ? !algorithm || !overlap : !tightness}
          >
            {" "}
            Rerun
          </Button>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            {tab === 3 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>CLUSTER</TableCell>
                    <TableCell>SEARCH VOLUME</TableCell>
                    <TableCell>KEYWORDS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 &&
                    data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, index: any) => {
                        const isExpanded = expandedRow === index;
                        const keywordArray = Array.isArray(row.keywords)
                          ? row.keywords
                          : row.keywords.split(", ");

                        return (
                          <TableRow key={index}>
                            <TableCell sx={{padding:"10px !important", borderBottom:"1px solid rgba(0, 0, 0, 0.06) !important"}}>{row.clusterName}</TableCell>
                            <TableCell sx={{padding:"10px !important", borderBottom:"1px solid rgba(0, 0, 0, 0.06) !important"}}>{row.searchVolume}</TableCell>
                            <TableCell sx={{padding:"10px !important", borderBottom:"1px solid rgba(0, 0, 0, 0.06) !important"}}>
                              <Box display="flex" alignItems="center">
                                <Box>
                                  {isExpanded ? (
                                    <>
                                      <Typography variant="body2">
                                        {row?.keywordsCount}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: 2,
                                          mt: 2,
                                        }}
                                      >
                                        {keywordArray.map(
                                          (keyword: any, i: number) => (
                                            <Typography key={i} variant="body2">
                                              {keyword}
                                            </Typography>
                                          )
                                        )}
                                      </Box>
                                    </>
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color:
                                          "var(--text-secondary, rgba(0, 0, 0, 0.60))",
                                        fontFeatureSettings:
                                          "'liga' off, 'clig' off",
                                      }}
                                    >
                                      {row?.keywordsCount}
                                    </Typography>
                                  )}
                                </Box>

                                <IconButton onClick={() => toggleExpand(index)}>
                                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            )}
            {tab === 4 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>TOPIC NAME</TableCell>
                    <TableCell>SEARCH VOLUME</TableCell>
                    <TableCell>CLUSTERS</TableCell>
                    <TableCell>KEYWORDS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataRes.length > 0 &&
                    dataRes
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, index: any) => {
                        const isClusterExpand = expandedClusterRow === index;
                        const isExpanded = expandedRow === index;

                        return (
                          <TableRow key={index}>
                            <TableCell sx={{padding:"10px !important", borderBottom:"1px solid rgba(0, 0, 0, 0.06) !important"}}>{row.topicName}</TableCell>
                            <TableCell sx={{padding:"10px !important", borderBottom:"1px solid rgba(0, 0, 0, 0.06) !important"}}>{row.searchVolume}</TableCell>
                            <TableCell sx={{padding:"10px !important", borderBottom:"1px solid rgba(0, 0, 0, 0.06) !important"}}>
                              <Box display="flex" alignItems="center">
                                <Box>
                                  {isClusterExpand ? (
                                    <>
                                      <Typography variant="body2">
                                        {row?.clusters.length}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: 2,
                                          mt: 2,
                                        }}
                                      >
                                        {row?.clusters.map(
                                          (cluster: any, i: number) => (
                                            <Typography key={i} variant="body2">
                                              {cluster}
                                            </Typography>
                                          )
                                        )}
                                      </Box>
                                    </>
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color:
                                          "var(--text-secondary, rgba(0, 0, 0, 0.60))",
                                        fontFeatureSettings:
                                          "'liga' off, 'clig' off",
                                      }}
                                    >
                                      {row?.clusters.length}
                                    </Typography>
                                  )}
                                </Box>

                                <IconButton
                                  onClick={() => toggleClusterExpand(index)}
                                >
                                  {isClusterExpand ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </IconButton>
                              </Box>
                            </TableCell>
                            <TableCell sx={{padding:"10px !important", borderBottom:"1px solid rgba(0, 0, 0, 0.06) !important"}}>
                              <Box display="flex" alignItems="center">
                                <Box>
                                  {isExpanded ? (
                                    <>
                                      <Typography variant="body2">
                                        {row?.keywords.length}
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: 2,
                                          mt: 2,
                                        }}
                                      >
                                        {row?.keywords.map(
                                          (keyword: any, i: number) => (
                                            <Typography key={i} variant="body2">
                                              {keyword}
                                            </Typography>
                                          )
                                        )}
                                      </Box>
                                    </>
                                  ) : (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color:
                                          "var(--text-secondary, rgba(0, 0, 0, 0.60))",
                                        fontFeatureSettings:
                                          "'liga' off, 'clig' off",
                                      }}
                                    >
                                      {row?.keywords.length}
                                    </Typography>
                                  )}
                                </Box>

                                <IconButton onClick={() => toggleExpand(index)}>
                                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100,200,300,400,500,1000]}
            component="div"
            count={tab === 3 ? data.length : dataRes.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Card>
    </>
  );
};

export default IntentClusters;
