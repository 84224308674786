/* eslint-disable react-hooks/exhaustive-deps */

import "./TableComponent3.scss";

import {
  Box,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomDialog from "../../components/Dialog/CustomDialog";
import React from "react";
import TablePagination from "@mui/material/TablePagination";

interface DataType {
  queries: string;
  searches: {
    value: number;
  };
}

type TableProps = {
  title: string;
  data?: DataType[];
  colSpan: number;
  message?: string;
  linkText: string;
  onLinkClick?: () => void;
  columns: string[];
};

const TableComponent3: React.FC<TableProps> = ({
  title,
  data,
  colSpan,
  message = "",
  linkText,
  onLinkClick,
  columns,
}) => {
  const [pageSite, setPageSite] = React.useState(0);
  const [rowsPerPageSite, setRowsPerPageSite] = React.useState(5);

  const handleChangePageSite = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageSite(newPage);
  };

  const handleChangeRowsPerPageSite = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageSite(parseInt(event.target.value));
    setPageSite(0);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Box className="Custom-table-wrapper3">
      <TableContainer component={Box}  sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "16px",
          minHeight: "455px",
          boxShadow: "none !important"
        }}>
        <Box className="table-headings" sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 1
          }}>
          <Typography variant="h6">{title}</Typography>
          
        </Box>

        <Table className="table-container">
          <TableHead className="table-header" sx={{ borderBottom: "1px solid #e0e0e0", padding: 0}} >
            <TableCell sx={{minWidth: '320px'}}></TableCell>
            {columns.includes("searches") && (
              <TableCell
                className="table-header-right-clk"
              >
                SEARCHES
              </TableCell>
            )}
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.slice(0, 10).map((row: any, index) => (
                <TableRow key={index} className="table-row" sx={{padding: "0 12px !important"}}>
                  <TableCell className="table-cell-name"  >
                    <Box className="table-cell-name-inner">
                      <Typography variant="body2" className="tablerow" sx={{padding: '6px 0'}}>{row.queries}</Typography>
                    </Box>
                  </TableCell>
                  {columns.includes("searches") && (
                    <TableCell className="table-cell-value tablerow">
                      <Box className="table-cell-value-text">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography variant="body2" className="tablerow" sx={{padding: '6px 0'}} >
                            {row.searches.value > 1000
                              ? `${(row.searches.value / 1000).toFixed(1)}K`
                              : row.searches.value.toString()}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "0.8rem",
                    }}
                  >
                    {message}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    <span>
                      {message && (
                        <Link
                          component="button"
                          variant="body1"
                          onClick={onLinkClick}
                          sx={{ color: "blue", textDecoration: "none" }}
                        >
                          Define
                        </Link>
                      )}{" "}
                      {linkText}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "auto",
          }}
        >
          {data && data.length > 0 && (
            <IconButton onClick={() => setOpen(true)}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </Box>
      </TableContainer>

      <CustomDialog open={open} onClose={handleClose} width={"100%"} backgroundDropColor={true} >
        <Box className="Custom-table-inner-wrapper3">
          <TableContainer sx={{ marginTop: "1.2rem", width: "760px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 1,
                marginBottom: 1,
              }}
            >
              <Typography variant="h6">{title}</Typography>
             
            </Box>
            <Table className="table-container" >
          <TableHead className="table-header" sx={{ borderBottom: "1px solid #e0e0e0", padding: 0}} >
            <TableCell sx={{minWidth: '320px'}}></TableCell>
            {columns.includes("searches") && (
              <TableCell
                className="table-header-right-clk"
              >
                SEARCHES
              </TableCell>
            )}
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data?.slice(
                pageSite * rowsPerPageSite,
                pageSite * rowsPerPageSite + rowsPerPageSite
              ).map((row: any, index) => (
                <TableRow key={index} className="table-row" sx={{padding: "0 12px !important"}} >
                  <TableCell className="table-cell-name" sx={{borderBottom:"none"}}>
                    <Box className="table-cell-name-inner">
                      <Typography variant="body2" className="tablerow" sx={{padding: '6px 0'}}>{row.queries}</Typography>
                    </Box>
                  </TableCell>
                  {columns.includes("searches") && (
                    <TableCell className="table-cell-value tablerow" sx={{borderBottom:"none"}}>
                      <Box className="table-cell-value-text">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography variant="body2" className="tablerow" sx={{padding: '6px 0'}} >
                            {row.searches.value > 1000
                              ? `${(row.searches.value / 1000).toFixed(1)}K`
                              : row.searches.value.toString()}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "0.8rem",
                    }}
                  >
                    {message}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    <span>
                      {message && (
                        <Link
                          component="button"
                          variant="body1"
                          onClick={onLinkClick}
                          sx={{ color: "blue", textDecoration: "none" }}
                        >
                          Define
                        </Link>
                      )}{" "}
                      {linkText}
                    </span>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={data ? data?.length : 0}
                  rowsPerPage={rowsPerPageSite}
                  page={pageSite}
                  onPageChange={handleChangePageSite}
                  onRowsPerPageChange={handleChangeRowsPerPageSite}
                  sx={{
                    color: "black", // Text color
                    "& .MuiTablePagination-selectIcon": {
                      color: "black", // Dropdown arrow near rows per page
                    },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "rgba(255, 255, 255, 0.1)", // Dropdown background
                          backdropFilter: "blur(18px)", // Blurred background for the dropdown
                          "& .MuiMenuItem-root": {
                            color: "black", // Text color for options
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)", // Slightly darker background on hover
                          },
                          "& .Mui-selected": {
                            backgroundColor:
                              "rgba(255, 255, 255, 0.3) !important", // Background color for selected option
                          },
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </TableContainer>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default TableComponent3;
