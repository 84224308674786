import './Sidebar.scss'

import {
  AddCircleOutline,
  Build,
  Build as BuildIcon,
  ExpandLess,
  ExpandMore,
  PeopleOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Box, Button, Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ArchitectureIcon from '@mui/icons-material/Architecture';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { userEntityTypeAction } from "../../store/userEntityType";

const AdminSidebar: React.FC = () => {


  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = useSelector((state: any) => state.auth);
  const [activeState, setActiveState] = useState(location.pathname);
  const [openMenu, setOpenMenu] = useState<{ [key: string]: boolean }>({
    business: false,
    tools: false,
    users: false,
  });
  const dispatch = useDispatch();



  useEffect(() => {
    if (activeState !== location.pathname) {
      setActiveState(location.pathname);
    }
  }, [location, activeState]);

  const handleOAuth2Redirect = (key: string) => {
    const userEntityType = {
      UniqueId: "a5bb73d1-c3a5-42e7-a3f1-36d836120f33",
      EntityType: key.toUpperCase(),
    };
    dispatch(userEntityTypeAction({ userEntityType }));
    if (key === "gsc") {
      navigate(
        "/gsc-login?code=4/0AcvDMrCoA1yEGgcoq3PbZLf5n_fJR7OyJJy3M8nGHCNGtgXQk3eUXWmByvkljvbhM7pNJw"
      );
    } else if (key === "ga4") {
      navigate("/google-analytics");
    }
  };

  const handleNavigateMenu = (key: string) => {
    if (key === "gsc" || key === "ga4") {
      handleOAuth2Redirect(key);
    } else {
      navigate(key);
    }
  };

  const toggleMenu = (menu: string) => {
    setOpenMenu((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const renderButtonItem = (text: string, icon: JSX.Element, route: string) => (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      onClick={() => handleNavigateMenu(route)}
      startIcon={icon}
      sx={{
        justifyContent: "flex-start",
        textAlign: "left",
        width: "100%",
      }}
    >
      {text}
    </Button>
  );

  const renderCollapsibleMenu = (
    menuName: string,
    icon: JSX.Element,
    items: Array<{ text: string; icon: JSX.Element; route: string }>
  ) => (
    <>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => toggleMenu(menuName)}
        startIcon={icon}
        endIcon={openMenu[menuName] ? <ExpandLess /> : <ExpandMore />}
        sx={{
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {menuName.charAt(0).toUpperCase() + menuName.slice(1)}
      </Button>
      <Collapse in={openMenu[menuName]} timeout="auto" unmountOnExit>
        {items.map((item, index) => (
          <div key={`${menuName}-${index}`}>
            {renderButtonItem(item.text, item.icon, item.route)}
          </div>
        ))}
      </Collapse>
    </>
  );
  


 

  return (
    <Box className="sidebar-menus">
      {(userDetails.roleid === 2) && renderCollapsibleMenu("tools", <BuildIcon />, [
        // { text: "GBP", icon: <StoreOutlined />, route: "gbp/review-list" },
        // { text: "GSC", icon: <BarChartOutlined />, route: "gsc" },
        // { text: "GA4", icon: <TimelineOutlined />, route: "ga4" },
        { text: "AI chatbot", icon: <ChatBubbleOutlineIcon />, route: "/ai-chatbot" },
        { text: "Web Scraping", icon: <BubbleChartIcon />, route: "/web-scraping" },
        { text: "keywordClustering", icon: <BubbleChartIcon />, route: "/keyword-clustering" },
        { text: "Web Tools", icon: <Build />, route: "/web-tools" },
        { text: "Topic Startegy Builder", icon: <ArchitectureIcon />, route: "/topic-startegy" },
      ])}

      {userDetails.roleid === 2 && renderCollapsibleMenu("users", <PeopleOutlined />, [
        { text: "Create User", icon: <AddCircleOutline />, route: "/create-user" },
        { text: "View Users", icon: <VisibilityOutlined />, route: "/view-users" },
      ])}
    </Box>
  );
};

export default AdminSidebar;
