import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import OutputArea from "./OutputArea"; // Import the OutputArea component
import axios from "axios";
import { urlConstants } from "../constants/globalConstants";

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fileDetails, setFileDetails] = useState<any>(null);
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [updates, setUpdates] = useState<any>([]); // Real-time updates via WebSocket
  const [outputDetails, setOutputDetails] = useState<any>(null); // Output Details state
  const [promptInput, setPromptInput] = useState(""); // State for the prompt input

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
    setFileDetails(null);
    setError("");
    setUpdates([]);
    setOutputDetails(null); // Reset output details when a new file is selected
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setError("Please select a file before uploading.");
      return;
    }

    const formData = new FormData();
    formData.append("configFile", selectedFile);
    // formData.append("promptInput", promptInput); // Include prompt input in the form data

    try {
      // const response = await axios.post("http://massic-core-utilities-alp-861974577.us-west-2.elb.amazonaws.com/api/workflows/upload", formData, {
        const response = await axios.post(urlConstants.WEB_TOOL_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { fileName, iniFilePath, workflowId, steps } = response.data;
      setFileDetails({ fileName, workflowId, steps, iniFilePath });
      setError("");
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("An error occurred while uploading the file.");
    }
  };

  const handleWorkflowExecution = () => {
    if (!fileDetails || !fileDetails.iniFilePath) {
      setError("Please upload a file before executing.");
      return;
    }

    try {
      // const ws = new WebSocket("ws://massic-core-utilities-alp-861974577.us-west-2.elb.amazonaws.com");
      const ws = new WebSocket(urlConstants.WEB_TOOL_WEB_SOCKET_URL);
      console.log("WebSocket connection established.", ws);

      ws.onopen = () => {
        setIsProcessing(true);
        ws.send(
          JSON.stringify({
            type: "start-workflow",
            filePath: fileDetails.iniFilePath,
            promptInput: promptInput, // Include prompt input in the WebSocket message
          })
        );
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.status === "info" || data.status === "update") {
            setUpdates((prevUpdates: any) => [...prevUpdates, data.message]);
          }

          if (data.outputDetails) {
            setOutputDetails(data.outputDetails); // Save output details
          }

          if (data.status === "completed") {
            setIsProcessing(false);
            setUpdates((prevUpdates: any) => [...prevUpdates, data.message]);
          } else if (data.status === "error") {
            setError(data.message);
            setIsProcessing(false);
          }
        } catch (error: any) {
          console.error("Error parsing WebSocket message:", error.message);
        }
      };

      ws.onerror = (err: any) => {
        console.error("WebSocket error:", err.message);
        setError("An error occurred during workflow execution.");
        setIsProcessing(false);
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed.");
      };
    } catch (error) {
      console.error(error);
    }
  };


  const handleDownload = () => {
  if (!outputDetails) return;

  const plainTextContent = `
Plain Text Output:
${outputDetails.PlainText || "No plain text output available."}
  `;

  // Check if ParsedTable has content
  let tableContent = "";
  if (outputDetails.ParsedTable && outputDetails.ParsedTable.length > 0) {
    tableContent = `
Parsed Table Output:
${JSON.stringify(outputDetails.ParsedTable, null, 2)}
    `;
  }

  const content = plainTextContent + tableContent;

  const blob = new Blob([content], { type: "text/plain" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = "workflow-output.txt";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};



  return (
    <Box sx={{ p: 4, maxWidth: 1000, mx: "auto", bgcolor: "white", boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h5" gutterBottom>
        Workflow Execution Tool
      </Typography>
      <TextField
        label="Prompt Input"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        value={promptInput}
        onChange={(e) => setPromptInput(e.target.value)}
        placeholder="Enter your prompt here"
        sx={{ mb: 3 }}
      />
      <TextField
        type="file"
        fullWidth
        variant="outlined"
        inputProps={{ accept: ".ini" }}
        onChange={handleFileChange}
        sx={{ mb: 3 }}
      />
      {selectedFile && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Selected File: <strong>{selectedFile.name}</strong>
        </Typography>
      )}
      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <Button variant="contained" color="primary" onClick={handleFileUpload}>
          Upload
        </Button>
        {isProcessing && <CircularProgress size={24} />}
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      {fileDetails && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Workflow Details
          </Typography>
          <Typography variant="body2">
            <strong>Workflow ID:</strong> {fileDetails.workflowId}
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Steps
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Step</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileDetails.steps.map((step: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{step.step_id}</TableCell>
                    <TableCell>{step.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="success"
            onClick={handleWorkflowExecution}
            sx={{ mt: 2 }}
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Execute Workflow"}
          </Button>
        </Box>
      )}
      {updates.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Workflow Updates
          </Typography>
          <ul>
            {updates.map((message: any, index: number) => (
              <li key={index}>
                <Typography variant="body2">{message}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
      {outputDetails && (
        <Box sx={{ mt: 4 }}>
          <OutputArea outputDetails={outputDetails} />

          <Button
            variant="contained"
            color="success"
            onClick={handleDownload}
            sx={{ mt: 2 }}
          >             Download Output
           </Button>
          
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
