import { IAPIConfig, useApi } from './useApi';

import { useState } from 'react';

const useImageUpload = (userDetails: { id: string }) => {
  const { doApi } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const uploadImage = async (base64String: string, fileName?: string): Promise<string> => {
    if (!base64String) {
      console.error("No Base64 string provided.");
      return "";
    }
  
    // Extract MIME type and Base64 data
    const matches = base64String.match(/^data:(.*?);base64,(.*)$/);
    if (!matches || matches.length !== 3) {
      console.error("Invalid Base64 format.");
      return "";
    }
  
    const mimeType = matches[1]; // Get MIME type (e.g., "image/png" or "application/pdf")
    const base64Data = matches[2]; // Extract actual Base64 data
  
    // Check if the MIME type is supported (only images and PDFs)
    const allowedMimeTypes = ["image/png", "image/jpeg", "application/pdf", "text/html"];
    if (!allowedMimeTypes.includes(mimeType)) {
      console.error("Unsupported file type:", mimeType);
      return "";
    }
  
    // Convert Base64 to binary data
    const byteCharacters = atob(base64Data);
    const byteArrays = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays[i] = byteCharacters.charCodeAt(i);
    }
  
    // Create Blob based on MIME type
    const blob = new Blob([byteArrays], { type: mimeType });
  
    // Generate filename if not provided
    if (!fileName) {
      const extension = mimeType.split("/")[1]; // Extract "png", "jpeg", or "pdf"
      fileName = `upload.${extension}`;
    }
  
    // Prepare FormData
    const formData: any = new FormData();
    formData.append("UploadFile", blob, fileName);
  
    try {
      setLoading(true);
      setError(null);
  
      const logoUploadConfig: IAPIConfig = {
        URL: `/Storage/UploadToBlob?Container=usr&Entity=logo&ItemId=${userDetails.id}`,
        method: "POST",
        payLoad: formData,
      };
  
      const res: any = await doApi(logoUploadConfig, "seedcore");
  
      return res.data.err === false ? res.data.uri : "";
    } catch (err: any) {
      setError(err.message);
      return "";
    } finally {
      setLoading(false);
    }
  };
  

  return { uploadImage, loading, error };
};

export default useImageUpload;
