import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import React from "react";

const OutputArea = ({ outputDetails }: any) => {
  if (!outputDetails) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" color="textSecondary">
          No output available.
        </Typography>
      </Box>
    );
  }

  const { PlainText, ParsedTable } = outputDetails;

  return (
    <Box sx={{ mt: 4 }}>
      {/* Display Plain Text */}
      {PlainText && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Plain Text Output:
          </Typography>
          <Box
            sx={{
              p: 2,
              bgcolor: "background.paper",
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 2,
              overflow: "auto",
              whiteSpace: "pre-wrap", // Preserve new lines and spaces without unnecessary symbols
              fontFamily: "monospace", // Optional: use monospace font for code-like text
              lineHeight: 1.5,
            }}
          >
            {PlainText}
          </Box>
        </Box>
      )}

      {/* Display Table Data */}
      {ParsedTable && ParsedTable.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Table Output:
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table stickyHeader  aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {Object.keys(ParsedTable[0]).map((header, index) => (
                    <TableCell key={index} sx={{ fontWeight: "bold" }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ParsedTable.map((row: any, rowIndex: number) => (
                  <TableRow key={rowIndex}>
                    {Object.values(row).map((cell: any, cellIndex: number) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default OutputArea;