/* eslint-disable react-hooks/exhaustive-deps */

import "./GSCLandingPage.scss";

import { ArrowRightOutlined, EyeOutlined } from "@ant-design/icons";
import { Card, Input } from "antd";
import  { IAPIConfig, useApi } from "../../../custom_hooks/useApi";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AreaChartComponent from "../../../components/charts/AreaChartComponent";
import ClicksIcon from "../../../components/svg/ClicksIcon";
import CustomToast from "../../../utils/cutomToast/CustomToast";
import Loader from "../../../utils/loader/Loader";
import SearchAndFilter from "../../../components/searchandfilter/SearchAndFilter";
import { urlConstants } from "../../../components/constants/globalConstants";
import { useSelector } from "react-redux";

interface ProfileData {
  keys: string[];
  clicks: number;
  impressions: number;
  ctr: number;
  position: number;
  url: string;
}

interface BusinessProfile {
  name: string;
  data: ProfileData[];
}

const { Search } = Input;

//only for commenting
//only for checking

const GSCLandingPage: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const { doApi } = useApi();
  const firstTime = useRef(true);
  const firstTimeForGSCSites = useRef(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [dateRange, setDateRange] = useState<string>("7 days");
  const [loading, setLoading] = useState(false);
  const [businessProfiles, setBusinessProfiles] = useState<BusinessProfile[]>(
    []
  );
  const [showClicks, setShowClicks] = useState(true);
  const [showImpressions, setShowImpressions] = useState(true);
  const [showCTR, setShowCTR] = useState(true);
  const [showPosition, setShowPosition] = useState(true);
  const { userEntityType } = useSelector(
    (state: any) => state.userEntityDetails
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (code && firstTime.current) {
      storeAuthCode();
      firstTime.current = false;
    }
  });

  useEffect(() => {
    try {
      if (userEntityType.UniqueId !== "" && firstTimeForGSCSites.current) {
        fetchAllSitesAnalytics(userEntityType.UniqueId, dateRange);
        firstTimeForGSCSites.current = false;
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
    }
  }, [dateRange]);

  useEffect(() =>{
    setTimeout(() =>{
      if(loading){
        setLoading(false);
     }
    }, 3000)
  }, [loading])

  const storeAuthCode = async () => {
    setLoading(true);
    const storeAuthConfig: IAPIConfig = {
      URL: `/Auth/StoreAuthForCustomer?uniqueId=${userEntityType.UniqueId}&authCode=${code}&redirectUri=${urlConstants.ENV_URL}/gsc-login`,
      method: "POST",
      payLoad: {},
    };
    const res: any = await doApi(storeAuthConfig, "seedcore");
    if (res.status === 200) {
      CustomToast(res.data.message, "success");
    } else {
      CustomToast("Failed to fetch access token and refresh token.", "error");
    }
  };

  const fetchAllSitesAnalytics = async (
    uniqueId: string,
    dateRange: string
  ) => {
    const fetchAllSitesConfig: IAPIConfig = {
      URL: `GSC/GetAllSitesAnalytics?Origin=ui&UniqueId=${uniqueId}&Period=${dateRange}`,
      method: "GET",
    };
    const res: any = await doApi(fetchAllSitesConfig, "seedcore");
    console.log(res);
    if (res.status === 200) {
      const rawData = res.data.data;
      console.log(rawData);
      const parsedData = parseData(rawData);
      setBusinessProfiles(parsedData);
    } else {
      CustomToast("Failed to fetch site analytics.", "error");
    }
    setLoading(false);
  };

  const parseData = (data: any[]) => {
    return data.map((item) => {
      const stats = JSON.parse(item.stats);
      const rows = stats.rows || [];
      return {
        name: item.url,
        data: rows,
      };
    });
  };

  const extractDomainName = (url: string) => {
    try {
      const { hostname } = new URL(url);
      const domain = hostname.replace(/^www\./, "");
      const domainName = domain.split(".")[0];
      return domainName;
    } catch (error) {
      console.error("Invalid URL:", url);
      return url;
    }
  };

  const handleNavigate = (profileName: string) => {
    const domainName = extractDomainName(profileName);
    const data = {
      uniqueId: userEntityType.UniqueId,
      website: profileName,
      origin: "ui",
    };
    navigate(`/gsc/${domainName}`, { state: data });
  };

  const calculateTotals = (data: ProfileData[]) => {
    let totalClicks = 0;
    let totalImpressions = 0;

    data.forEach((item) => {
      totalClicks += item.clicks;
      totalImpressions += item.impressions;
    });

    return {
      totalClicks,
      totalImpressions,
    };
  };

  const formatCount = (count: number) => {
    return count > 1000 ? `${(count / 1000).toFixed(1)}K` : count.toString();
  };

  const filterProfiles = () => {
    return businessProfiles.filter((profile) => {
      const { totalClicks, totalImpressions } = calculateTotals(profile.data);
      return (
        profile.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (totalClicks > 0 || totalImpressions > 0)
      );
    });
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    firstTimeForGSCSites.current = true;
  };

  const toggleGraph = (graph: string) => {
    switch (graph) {
      case "clicks":
        setShowClicks((prev) => {
          const newState = !prev;
          if (!newState && !showImpressions && !showCTR && !showPosition) {
            return true;
          }
          return newState;
        });
        break;
      case "impressions":
        setShowImpressions((prev) => {
          const newState = !prev;
          if (!newState && !showClicks && !showCTR && !showPosition) {
            return true;
          }
          return newState;
        });
        break;
      case "ctr":
        setShowCTR((prev) => {
          const newState = !prev;
          if (!newState && !showClicks && !showImpressions && !showPosition) {
            return true;
          }
          return newState;
        });
        break;
      case "position":
        setShowPosition((prev) => {
          const newState = !prev;
          if (!newState && !showClicks && !showImpressions && !showCTR) {
            return true;
          }
          return newState;
        });
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="search-and-filter">
        <div className="search">
          <Search
            placeholder="Search Business Profiles"
            onSearch={handleSearch}
          />
        </div>
        <SearchAndFilter
          dateRange={dateRange}
          handleDateRangeChange={handleDateRangeChange}
          showClicks={showClicks}
          showImpressions={showImpressions}
          showCTR={showCTR}
          showPosition={showPosition}
          toggleGraph={toggleGraph}
        />
      </div>
      <Loader spinning = {loading}>
      <div className="charts-container">
        {filterProfiles().map((profile, index) => {
          const { totalClicks, totalImpressions } = calculateTotals(
            profile.data
          );
          return (
            <div key={index} className="card-container">
              <Card className="business-metrics">
                <div className="card-content">
                  <div className="profile-title">
                    <button
                      onClick={() => handleNavigate(profile.name)}
                      className="btnStyle"
                    >
                      <div className="btnContainer">
                        <h3>{profile.name}</h3>
                        <ArrowRightOutlined style={{ fontSize: "14px" }} />
                      </div>
                    </button>
                    <div className="totals">
                      <p>
                        <ClicksIcon className="icon-clicks" />
                        {formatCount(totalClicks)}
                      </p>
                      <p>
                        <EyeOutlined className="icon-impressions" />
                        {formatCount(totalImpressions)}
                      </p>
                    </div>
                  </div>
                  <div className="line-chart">
                    <AreaChartComponent
                      data={profile.data}
                      showClicks={showClicks}
                      showImpressions={showImpressions}
                      showCTR={showCTR}
                      showPosition={showPosition}
                    />
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      </div>
      </Loader>
    </div>
  );
};

export default GSCLandingPage;
