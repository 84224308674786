/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import { Button, Stack, TextField, Typography } from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import CustomToast from "../../utils/cutomToast/CustomToast";
import Drawer from "@mui/material/Drawer";
import Loader from "../../utils/loader/Loader";
import { QRCodeCanvas } from "qrcode.react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import jsPDF from "jspdf";
import { urlConstants } from "../../components/constants/globalConstants";
import useImageUpload from "../../custom_hooks/useImageUpload";
import { useSelector } from "react-redux";

type DrawerComponentProps = {
  open: boolean;
  toggleDrawer: any;
  reviewLink: string;
  setPopUpData?: any;
  popUpData?: any;
  isEnable?: boolean;
};

type TransformedObject = {
  QrCodeType: string;
  QrCodePrintMsg: string;
  QrCodeMailMsg: string;
  RecipientsInput: string;
  QrCodeBase64: string;
  Recipients: string[];
};

const DrawerComponent: React.FC<DrawerComponentProps> = ({
  open,
  toggleDrawer,
  reviewLink,
  setPopUpData,
  popUpData,
  isEnable = false,
}) => {
  const [campaignOption, setCampaignOption] = React.useState(
    popUpData?.QRCodeDetails?.Type || "codeOnly"
  );
  const { userDetails } = useSelector((state: any) => state.auth);
  const { uploadImage, loading: loadingForm } = useImageUpload({
    id: userDetails.id,
  });
  const [loading, setLoading] = React.useState(loadingForm);
  const { doApi } = useApi();
  const handleChange = (event: React.MouseEvent<HTMLElement>, opt: string) => {
    setCampaignOption(opt);
  };

  React.useEffect(() => {
    if (campaignOption) {
      setcampaignData({ ...campaignData, QrCodeType: campaignOption });
    }
  }, [campaignOption]);

  const [campaignData, setcampaignData] = React.useState<TransformedObject>({
    QrCodeType: popUpData?.QRCodeDetails?.Type ?? "",
    QrCodePrintMsg: popUpData?.QRCodeDetails?.PrintMsg ?? "",
    QrCodeMailMsg: popUpData?.QRCodeDetails?.MailMsg ?? "",
    RecipientsInput: "",
    QrCodeBase64: "",
    Recipients: popUpData?.QRCodeRecipients ?? [],
  });

  React.useEffect(() => {
    if (open && popUpData) {
      setcampaignData({
        QrCodeType: popUpData?.QRCodeDetails?.Type ?? "",
        QrCodePrintMsg: popUpData?.QRCodeDetails?.PrintMsg ?? "",
        QrCodeMailMsg: popUpData?.QRCodeDetails?.MailMsg ?? "",
        RecipientsInput: "",
        QrCodeBase64: "",
        Recipients: popUpData?.QRCodeRecipients ?? [],
      });
    }
  }, [open, popUpData]);

  React.useEffect(() => {
    if (
      campaignData &&
      campaignData.Recipients !== undefined &&
      campaignData.Recipients.length > 0 &&
      !isEnable
    ) {
      setPopUpData(campaignData);
    }
  }, [campaignData]);

  const qrCodeCanvasRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    if (reviewLink && qrCodeCanvasRef.current) {
      const canvas = qrCodeCanvasRef.current;
      setcampaignData({ ...campaignData, QrCodeBase64: canvas.toDataURL() });
    }
  }, [reviewLink, qrCodeCanvasRef.current]);

  const handleAddRecipient = () => {
    if (
      campaignData.Recipients !== undefined &&
      campaignData.Recipients.length < 10
    ) {
      setcampaignData({
        ...campaignData,
        Recipients: [...campaignData.Recipients, campaignData.RecipientsInput],
        RecipientsInput: "",
      });
    }
  };

  const sendQrCodeEmail = async () => {
    setLoading(true);
    try {
      // Ensure the QR code canvas exists and extract data
      const qrCodeDataUrl = qrCodeCanvasRef.current
        ? qrCodeCanvasRef.current.toDataURL("image/png")
        : null;

      if (!campaignData?.Recipients || campaignData.Recipients.length === 0) {
        throw new Error("No recipients provided.");
      }
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      const fileName = `qr_code_${timestamp}.png`;
      const imageURL = await uploadImage(qrCodeDataUrl || "", fileName);
      let emailPayload;

      if (campaignOption === "printOnly") {
        const generateBase64PDF = async () => {
          const doc = new jsPDF();
          doc.text(campaignData.QrCodePrintMsg, 80, 30, { align: "center" });

          doc.setLineDashPattern([3, 3], 0);
          doc.rect(40, 20, 130, 150);

          doc.addImage(qrCodeDataUrl || "", "PNG", 60, 50, 90, 90);

          // Convert to Base64
          const pdfBase64 = doc.output("datauristring").split(",")[1];
          const fileName = `qr_code_${timestamp}.pdf`;
          const uploadedUrl = await uploadImage(
            `data:application/pdf;base64,${pdfBase64}`,
            fileName
          );

          return uploadedUrl;
        };

        const pdfURL = await generateBase64PDF();

        emailPayload = {
          from: urlConstants.SENDER_EMAIL_ID,
          to:"",
          subject: `${urlConstants.DOMAIN} QR Code Campaign`,
          cc: "",
          textbody: campaignData.QrCodeMailMsg || "",
          htmlbody: `
              <p>Dear User,</p>
              <p>Please find your QR code attached as a PDF.</p>
              <p>${campaignData.QrCodeMailMsg || "N/A"}</p>
            `,
          attachmentContent: pdfURL,
          isMultiple: true,
          tos: campaignData?.Recipients.length > 0 ? campaignData?.Recipients : [],
        };
      } else if (campaignOption === "codeOnly") {
        emailPayload = {
          from: urlConstants.SENDER_EMAIL_ID,
          to:"",
          subject: `${urlConstants.DOMAIN} QR Code Campaign`,
          cc: "",
          textbody: campaignData.QrCodeMailMsg || "",
          htmlbody: `
              <p>Dear User,</p>
              <p>${campaignData.QrCodeMailMsg || "Here is your QR Code:"}</p>
              <img src="${imageURL}" alt="QR Code" width="600" height="600" />
            `,
          isMultiple: true,
          tos:
            campaignData?.Recipients.length > 0 ? campaignData?.Recipients : [],
        };
      } else {
        throw new Error("Failed to process the QR code.");
      }

      const sendEmailConfig: IAPIConfig = {
        URL: "/Email/SendMail",
        method: "POST",
        payLoad: emailPayload,
      };

      try {
        const emailRes: any = await doApi(sendEmailConfig, "seedcore");
        if (emailRes.data.err === false) {
          toggleDrawer(false)();
          CustomToast(
            `Email sent successfully to ${campaignData?.Recipients.join(",")}.`,
            "success"
          );
        } else {
          throw new Error(
            `Failed to send email to ${campaignData?.Recipients.join(",")}.`
          );
        }
      } catch (err: any) {
        CustomToast(`Failed to process email to clients.`, "error");
        console.error(`Failed to process email to clients.`, err);
      }
    } catch (err: any) {
      CustomToast(`Failed to process email to clients.`, "error");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const DrawerList = (
    <Box sx={{ minWidth: "400px", padding: "24px 32px", bgcolor: "#F6F8F8" }}>
      <Stack direction="row" justifyContent="flex-end">
        <CloseIcon
          sx={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
            color: "#0F4343",
          }}
          onClick={toggleDrawer(false)}
        />
      </Stack>
      <Typography sx={{ marginTop: 2, marginBottom: 2 }} variant="h6">
        Email QR Code
      </Typography>
      <Box>
        <ToggleButtonGroup
          color="primary"
          value={campaignOption}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{
            height: "38px",
            color: "#0F4343",
            fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          {/* <ToggleButton sx={{ textTransform: "capitalize" }} value="printOnly">
            Print Ready
          </ToggleButton> */}
          <ToggleButton sx={{ textTransform: "capitalize" }} value="codeOnly">
            Code Only
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        {campaignOption === "codeOnly" ? (
          <QRCodeCanvas
            ref={qrCodeCanvasRef}
            value={reviewLink}
            size={160}
            includeMargin
          />
        ) : (
          <Stack
            direction="column"
            sx={{
              border: "2px solid rgba(0, 0, 0, 0.5)",
              borderStyle: "dashed",
              padding: "3 4",
              width: "224px",
              height: "292px",
              bgcolor: "#FFFFFF",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2">
              {campaignData?.QrCodePrintMsg}
            </Typography>
            <QRCodeCanvas
              ref={qrCodeCanvasRef}
              value={reviewLink}
              size={160}
              includeMargin
            />
          </Stack>
        )}
      </Box>
      <Box>
        {campaignOption === "printOnly" && (
          <TextField
            label="Print Copy"
            variant="filled"
            fullWidth
            disabled={isEnable}
            value={campaignData?.QrCodePrintMsg}
            onChange={(e) =>
              setcampaignData({
                ...campaignData,
                QrCodePrintMsg: e.target.value,
              })
            }
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                maxHeight: "56px",
              },
            }}
          />
        )}
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography variant="subtitle1">Message</Typography>
          <TextField
            sx={{ marginTop: 1 }}
            label="Message"
            variant="filled"
            disabled={isEnable}
            multiline
            rows={3}
            fullWidth
            value={campaignData?.QrCodeMailMsg}
            onChange={(e) =>
              setcampaignData({
                ...campaignData,
                QrCodeMailMsg: e.target.value,
              })
            }
          />
        </Box>
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography variant="subtitle1">Recipients</Typography>
          <TextField
            sx={{ marginTop: 1 }}
            label="Client Email"
            variant="filled"
            fullWidth
            disabled={isEnable}
            value={campaignData?.RecipientsInput || ""}
            onChange={(e) =>
              setcampaignData({
                ...campaignData,
                RecipientsInput: e.target.value,
              })
            }
          />
          <Button
            sx={{ marginTop: 1 }}
            variant="outlined"
            disabled={isEnable}
            startIcon={<AddIcon />}
            onClick={handleAddRecipient}
          >
            Add
          </Button>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body2">Recipients List:</Typography>
            <Stack direction="column" sx={{ marginTop: 1 }}>
              {campaignData &&
                campaignData.Recipients !== undefined &&
                campaignData.Recipients.length > 0 &&
                campaignData.Recipients.map((email, index) => (
                  <Typography key={index} variant="body2">
                    {email}
                  </Typography>
                ))}
            </Stack>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Button
              sx={{ marginTop: 1 }}
              variant="contained"
              onClick={sendQrCodeEmail}
            >
              Send Email
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer anchor="right" open={open}>
      <Loader spinning={loading}>{DrawerList}</Loader>
    </Drawer>
  );
};

export default DrawerComponent;
