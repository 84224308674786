/* eslint-disable no-useless-escape */

import "./ProductsTable.scss";

import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import CSVDataUpload from "./CSVDataUpload/CSVDataUpload";
import CloseIcon from "@mui/icons-material/Close";
import CustomDialog from "../../components/Dialog/CustomDialog";
import CustomToast from "../../utils/cutomToast/CustomToast";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import Papa from "papaparse";
import saveAs from "file-saver";
import { useApi } from "../../custom_hooks/useApi";
import { useBusinessProfileApis } from "../../custom_hooks/useBusinessProfileApis";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Import papaparse for CSV parsing

//import { deleteProductService } from '../../store/businessSlice';

const ProductsTable = () => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  // const [currentProductIndex, setCurrentProductIndex] = useState<number | null>(null);
  // const [newCategory, setNewCategory] = useState('');

  const { doApi } = useApi();

  const location = useLocation();

  const {
    GetBusinessProfilesDataByUniqueID,
    UpdateBusinessProfilesByUniqueID,
  } = useBusinessProfileApis();

  const businessDatas = useSelector(
    (state: any) => state.businessDetails.profileDataByUniqueID
  ); // Get profileDataByUniqueID from Redux store

  const { businessData } = location.state || {};

  // const [businessDatas, setBusinessDatas] = useState<any>(businessData || null);
  const businessref = useRef(true);
  useEffect(() => {
    if (businessref.current) {
      GetBusinessProfilesDataByUniqueID(businessData.UniqueId);
      businessref.current = false;
    }
  }, [businessData.UniqueId, GetBusinessProfilesDataByUniqueID]);

  // const handleCategoryClose = () => {
  //   setAnchorEl(null);
  //   setCurrentProductIndex(null);
  // };

  // const handleCategoryDelete = (category: string) => {
  //   setSelectedCategories((prev) => prev.filter((cat) => cat !== category));
  // };

  // const handleCategoryAdd = async () => {
  //   if (newCategory && !selectedCategories.includes(newCategory)) {
  //     setSelectedCategories((prev) => [...prev, newCategory]);
  //     setNewCategory('');

  //     if (currentProductIndex !== null) {
  //       products[currentProductIndex].categories = [...selectedCategories, newCategory];
  //     }
  //   }
  // };

  // const open = Boolean(anchorEl);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // const [filePreview, setFilePreview] = useState<string | null>(null);
  const [dialogState, setDialogState] = useState<{
    fileUpload: boolean;
    filePreview: boolean;
    settings: boolean;
    deleteConfirm: boolean;
  }>({
    fileUpload: false,
    filePreview: false,
    settings: false,
    deleteConfirm: false,
  });
  const handleDownloadTemplate = () => {
    const csvTemplate = [
      ["name", "description", "website"],
      ["John Doe", "write description", "google.com"],
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvTemplate.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    saveAs(encodedUri, "addproducttemplate.csv");
  };
  const toggleDialog = (dialogName: keyof typeof dialogState) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialogName]: !prevState[dialogName],
    }));
  };

  const [fileData, setFileData] = useState<any[]>([]);

  const parseCSVFile = (file: File) => {
    Papa.parse(file, {
      complete: (result: any) => {
        // Extract the headers (first row) from the CSV
        const headers = result.data[0];

        // Filter the rest of the rows (excluding the first row) to remove empty rows
        const filteredData = result.data.slice(1).filter((row: any) => {
          return Object.values(row).some(
            (value) => value !== null && value !== ""
          );
        });
        console.log("filteredData ~ filteredData:", filteredData);

        // Re-add the headers at the start of the filtered data
        const finalData = [headers, ...filteredData];
        console.log("filteredData ~ finalData:", finalData);

        setFileData(finalData); // Set the cleaned-up data to state
      },
      header: true, // If CSV has headers
    });
  };

  // Handle View Preview Button
  const handleViewPreview = (selectedFile: File | null) => {
    if (selectedFile) {
      const fileType = selectedFile.type;

      if (fileType === "text/csv") {
        parseCSVFile(selectedFile); // Parse CSV before showing preview
      }

      toggleDialog("filePreview"); // Open the preview dialog
    }
  };

  const handleClosePreview = () => {
    toggleDialog("filePreview");
    setFileData([]);
    // setFilePreview(null);
  };

  const handleFileUploadDialog = () => {
    toggleDialog("fileUpload");
    setFileData([]);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [validProductServices, setValidProductServices] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    website: "",
  });

  const uploadProductData = async () => {
    setLoading(true);
    try {
      let companyProfileConfig: any = {
        URL: `/Profile/UpdateProductServices?uniqueId=${businessData?.UniqueId}`,
        method: "POST",
        payLoad: fileData,
      };

      const res: any = await doApi(companyProfileConfig, "seedcore");

      if (res.status === 200) {
        // GetBusinessProfilesData();
        GetBusinessProfilesDataByUniqueID(businessData.UniqueId);

        toggleDialog("filePreview");
        CustomToast("Product and Services Updated successfully", "success");
      } else {
        CustomToast(
          `${
            res.response.data.message
              ? res.response.data.message
              : "Something went wrong"
          }`,
          "error"
        );
      }
    } catch (error: any) {
      CustomToast(error.message || "An error occurred", "error");
    } finally {
      setLoading(false);
    }
  };

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const handleOpenConfirmDialog = (index: number) => {
    setDeleteIndex(index);
    toggleDialog("deleteConfirm");
  };

  const handleCloseConfirmDialog = () => {
    toggleDialog("deleteConfirm");
    setDeleteIndex(null);
  };

  const handleConfirmDelete = async () => {
    if (deleteIndex !== null) {
      // dispatch(deleteProductService(deleteIndex));

      UpdateBusinessProfilesByUniqueID(businessDatas.UniqueId, {
        ...businessDatas,
        ProductsServices: businessDatas.ProductsServices.filter(
          (_: any, index: number) => index !== deleteIndex
        ), // Filter out the deleted product
      });

      handleCloseConfirmDialog();
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setNewProduct((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleOutsideClick = async () => {
    if (newProduct.name && newProduct.description && newProduct.website) {
      setLoading(true);
      const finalPayload = [newProduct];
      try {
        let productsConfig: any = {
          URL: `/Profile/UpdateProductServices?uniqueId=${businessData?.UniqueId}`,
          method: "POST",
          payLoad: finalPayload,
        };

        const res: any = await doApi(productsConfig, "seedcore");

        if (res.status === 200) {
          GetBusinessProfilesDataByUniqueID(businessData.UniqueId);
          setIsEditing(false);
          setNewProduct({
            name: "",
            description: "",
            website: "",
          });
          CustomToast("Product and Services Updated successfully", "success");
        } else {
          CustomToast(
            `${
              res.response.data.message
                ? res.response.data.message
                : "Something went wrong"
            }`,
            "error"
          );
        }
      } catch (error: any) {
        CustomToast(error.message || "An error occurred", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box className="products-table-container">
      <TableContainer>
        <Table className="table-container">
          <TableHead className="table-header">
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              {/* <TableCell>Categories</TableCell>
              <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                Unique Selling Point (USP)
                <InfoIcon sx={{ width: '18px', height: '18px', color: '#2F2F30', marginLeft: '6px' }} />
              </TableCell> */}
              <TableCell>Link</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell colSpan={6}>
                <Typography
                  variant="body1"
                  className="add-product"
                  onClick={() => setIsEditing(true)}
                >
                  + Product or Service
                </Typography>
              </TableCell>
            </TableRow>
            {isEditing && (
              <TableRow>
                <TableCell>
                  <TextField
                    label="Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={newProduct.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={2}
                    size="small"
                    fullWidth
                    value={newProduct.description}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    label="Website"
                    variant="outlined"
                    rows={2}
                    size="small"
                    fullWidth
                    value={newProduct.website}
                    onChange={(e) =>
                      handleInputChange("website", e.target.value)
                    }
                  />
                </TableCell>
                {/* Save (✔️) and Cancel (❌) Buttons */}
                <TableCell>
                  <IconButton
                    onClick={() => {
                      const { name, description, website } = newProduct;
                      const trimmedName = name?.trim();
                      const trimmedDescription = description?.trim();
                      const trimmedWebsite = website?.trim();

                      // Website validation regex
                      const websiteRegex =
                        /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}([\/?#].*)?$/;

                      if (
                        !trimmedName ||
                        !trimmedDescription ||
                        !trimmedWebsite
                      ) {
                        CustomToast(
                          "Please fill all fields correctly",
                          "error"
                        );
                      } else if (!websiteRegex.test(trimmedWebsite)) {
                        CustomToast(
                          "Please enter a valid website link",
                          "error"
                        );
                      } else {
                        handleOutsideClick();
                      }
                    }}
                  >
                    <DoneIcon style={{ color: "green" }} />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setIsEditing(false);
                      setNewProduct({
                        name: "",
                        description: "",
                        website: "",
                      });
                    }}
                  >
                    <CloseIcon style={{ color: "red" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
            {businessDatas &&
              businessDatas?.ProductsServices?.length > 0 &&
              businessDatas?.ProductsServices.map(
                (product: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {product.Name}
                    </TableCell>
                    <TableCell>{product.Description}</TableCell>

                    <TableCell>
                      <Link
                        href={product.Website}
                        underline="hover"
                        className="link"
                      >
                        {product.Website}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        className="delete-icon"
                        onClick={() => handleOpenConfirmDialog(index)}
                      >
                        <DeleteIcon
                          className="icon"
                          sx={{ color: "#093535" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Popover for Category Management */}
      {/* <Popover
      id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}  
        onClose={handleCategoryClose}
        anchorOrigin={{
          vertical: 'bottom',  
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',  
          horizontal: 'left',
        }}
      >
        <Box p={2} sx={{ width: '360px' }}>
          <Typography variant="subtitle1">Auto-generated Categories {selectedCategories.length}/6</Typography>
          <List>
            {selectedCategories.map((category, idx) => (
              <ListItem key={idx} disableGutters>
                <IconButton onClick={() => handleCategoryDelete(category)}>
                  <DeleteOutlineIcon className='delete-icon' sx={{ color: '#093535' }} />
                </IconButton>
                <Typography>{category}</Typography>
              </ListItem>
            ))}
          </List>

          <TextField
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            label="Type to add your own"
            fullWidth
            variant="filled"
            size='small'
            sx={{ marginTop: 1, marginBottom: 1 }}
            InputProps={{
              endAdornment: newCategory ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleCategoryAdd}
                    sx={{ color: '#093535' }}
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />

        </Box>
      </Popover> */}

      {/* CSV Actions */}
      <Box className="table-actions">
        <Box className="csv-links">
          <Link onClick={handleDownloadTemplate}>Download CSV Template</Link>
          <Link onClick={handleFileUploadDialog}>Import CSV</Link>
        </Box>
      </Box>

      {/* File Upload Dialog */}
      <CustomDialog
        open={dialogState.fileUpload}
        onClose={() => toggleDialog("fileUpload")}
        title="Import CSV"
      >
        <CSVDataUpload
          handleViewPreview={handleViewPreview}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          fileSupported={".csv"}
          fileData={fileData}
          setFileData={setFileData}
          parseCSVFile={parseCSVFile}
          toggleDialog={toggleDialog}
          setValidProductServices={setValidProductServices}
        />
      </CustomDialog>

      {/* Dialog for File Preview */}
      <CustomDialog
        open={dialogState.filePreview}
        onClose={handleClosePreview}
        title="File Preview"
        minWidth="200px"
      >
        {/* Preview for CSV */}
        {selectedFile?.type === "text/csv" &&
          (fileData && fileData.length > 0 ? (
            <Box sx={{ paddingTop: 3 }}>
              <table border={1}>
                <thead>
                  <tr>
                    {fileData[0] &&
                      Object.keys(fileData[0]).map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {fileData.map((row: any, index: number) => (
                    <tr key={index}>
                      {Object.values(row).map((val, i) => (
                        <td key={i}>{String(val)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {validProductServices && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className="upload-btn"
                    onClick={uploadProductData}
                  >
                    {loading ? "Importing..." : "Import"}
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <Box sx={{ paddingTop: 2, paddingBottom: 3 }}>Loading...</Box>
          ))}
      </CustomDialog>

      {/* Confirm Delete Dialog */}
      <CustomDialog
        open={dialogState.deleteConfirm}
        onClose={handleCloseConfirmDialog}
        title="Delete Product"
        // onConfirm={handleConfirmDelete}
      >
        <DialogContentText sx={{ marginTop: 2, marginBottom: 1.5 }}>
          Are you sure you want to delete this product?
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmDialog}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </CustomDialog>
    </Box>
  );
};

export default ProductsTable;
