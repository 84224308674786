const PhoneFrameOuter: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="355"
    height="127"
    viewBox="0 0 355 127"
    fill="none"
  >
    <g filter="url(#filter0_f_1984_36949)">
      <ellipse cx="177.5" cy="63.25" rx="117.5" ry="3" fill="#453E31" />
    </g>
    <defs>
      <filter
        id="filter0_f_1984_36949"
        x="0"
        y="0.25"
        width="355"
        height="126"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="30"
          result="effect1_foregroundBlur_1984_36949"
        />
      </filter>
    </defs>
  </svg>
);

export default PhoneFrameOuter;
