/* eslint-disable react-hooks/exhaustive-deps */
// Dashboard.tsx

import "./AdminDashboard.scss";

import {
  Box,
  Card,
  InputAdornment,
  Rating,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { IAPIConfig, useApi } from "../../custom_hooks/useApi";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomDashboardAreaCharts from "./DashboardAreaCharts/CustomDashboardAreaCharts";
import CustomToast from "../../utils/cutomToast/CustomToast";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import Label from "../../components/Label";
import LazyLoad from "react-lazyload";
import Loader from "../../utils/loader/Loader";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import StarIcon from "@mui/icons-material/Star";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { setExpandedBusiness } from "../../store/businessSlice";
import { useNavigate } from "react-router-dom";

type TransformedObject = {
  Name: string;
  Description: string;
  Website: string;
  ProductsServices: string[];
  LocationType: string;
  Locations: string[];
  CustomerPersonas: { personName: string; personDescription: string }[] | null;
  SellingPoints: string[] | null;
  BusinessObjective: string | null;
  Competitors: { name: string; website: string }[] | null;
  UniqueId: string;
};

const AdminDashboard: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [month, setMonth] = useState<string>("3 months");
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [ga4BusinessPreviews, setGa4BusinessPreviews] = useState<any>([]);
  const [gscBusinessPreviews, setGscBusinessPreviews] = useState<any>([]);
  const [gbpBusinessPreviews, setGbpBusinessPreviews] = useState<any>([]);
  const [filteredGa4, setFilteredGa4] = useState<any[]>([]);
  const [filteredGsc, setFilteredGsc] = useState<any[]>([]);
  const [filteredGbp, setFilteredGbp] = useState<any[]>([]);
  const { doApi } = useApi();
  const { userDetails } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    // Add event listener to update screen height on resize
    window.addEventListener("resize", updateScreenHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);
  

  const businessProfiles = useSelector((state: any) => state.businessDetails.profiles); 

  useEffect(() => {
    const filterData = (data: any[]) =>
      data.filter((item) =>
        item.url.toLowerCase().includes(searchQuery.toLowerCase())
      );

    setFilteredGa4(filterData(ga4BusinessPreviews));
    setFilteredGsc(filterData(gscBusinessPreviews));
    setFilteredGbp(filterData(gbpBusinessPreviews));
  }, [
    searchQuery,
    ga4BusinessPreviews,
    gscBusinessPreviews,
    gbpBusinessPreviews,
  ]);

  const fetchGa4AllBusinessPreviews = useCallback(async () => {
    try {
      const payload = {
        origin: "ui",
        useruniqueId: userDetails.uniqueId,
        mode: "web",
        period: month,
        dimensions: [{ name: "date" }],
        metrics: [{name:"Key events"},{name:"Sessions"}],
      };
      const fetchGa4AllBusinessPreviewsConfig: IAPIConfig = {
        URL: "GA4/FetchAllBusinessPreviews",
        method: "POST",
        payLoad: payload,
      };
      const res: any = await doApi(
        fetchGa4AllBusinessPreviewsConfig,
        "seedcore"
      );

      if (res.status === 200 && !res.data.err) {
        setGa4BusinessPreviews(res.data.data);
      } else {
        setGa4BusinessPreviews([]);
        CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
      setGa4BusinessPreviews([]);
    }
  }, [userDetails.uniqueId, month]);

  const fetchGscAllBusinessPreviews = useCallback(async () => {
    try {
      const payload = {
        origin: "ui",
        useruniqueId: userDetails.uniqueId,
        mode: "organic",
        period: month,
        dimensions: [{ name: "date" }],
      };
      const fetchGscAllBusinessPreviewsConfig: IAPIConfig = {
        URL: "GSC/FetchAllBusinessPreviews",
        method: "POST",
        payLoad: payload,
      };
      const res: any = await doApi(
        fetchGscAllBusinessPreviewsConfig,
        "seedcore"
      );

      if (res.status === 200 && !res.data.err) {
        setGscBusinessPreviews(res.data.data);
      } else {
        setGscBusinessPreviews([]);
        CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
      setGscBusinessPreviews([]);
    }
  }, [userDetails.uniqueId, month]);

  const fetchGbpAllBusinessPreviews = useCallback(async () => {
    try {
      const payload = {
        origin: "ui",
        useruniqueId: userDetails.uniqueId,
        mode: "web",
        period: month,
        dimensions: [{ name: "date" }],
        metrics: [
          {
            name: "Sessions",
          },
          {
            name: "Key events",
          },
        ],
      };
      const fetchGbpAllBusinessPreviewsConfig: IAPIConfig = {
        URL: "Review/FetchAllBusinessPreviews",
        method: "POST",
        payLoad: payload,
      };
      const res: any = await doApi(
        fetchGbpAllBusinessPreviewsConfig,
        "seedcore"
      );

      if (res.status === 200 && !res.data.err) {
        setGbpBusinessPreviews(res.data.data);
      } else {
        setGbpBusinessPreviews([]);
        CustomToast(res.data.message, "error");
      }
    } catch (err: any) {
      CustomToast(err.message, "error");
      setGbpBusinessPreviews([]);
    }
  }, [userDetails.uniqueId, month]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        switch (selectedTab) {
          case 0:
            await fetchGscAllBusinessPreviews();
            break;
          case 1:
            await fetchGa4AllBusinessPreviews();
            break;
          case 2:
            await fetchGbpAllBusinessPreviews();
            break;
          default:
            break;
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [selectedTab, month, fetchGscAllBusinessPreviews, fetchGa4AllBusinessPreviews, fetchGbpAllBusinessPreviews]);

  const timePerios = useMemo(
    () => [
      {
        id: 1,
        label: "7 days",
        value: "7 days",
      },
      {
        id: 2,
        label: "14 days",
        value: "14 days",
      },
      {
        id: 3,
        label: "28 days",
        value: "28 days",
      },
      {
        id: 4,
        label: "3 months",
        value: "3 months",
      },
      {
        id: 5,
        label: "6 months",
        value: "6 months",
      },
      {
        id: 6,
        label: "12 months",
        value: "12 months",
      },
    ],
    []
  );

  const extractDomainName = (url: string) => {
    try {
      // Create a new URL object to extract the hostname
      const hostname = new URL(url).hostname;
      const domain = hostname.replace(/^www\./, "");
      return domain.split(".")[0];
    } catch (error) {
      console.error("Invalid URL:", url);
      return url;
    }
  };

  const handleExpandBusiness = (businessData: TransformedObject) => {
    setTimeout(() => {
      dispatch(setExpandedBusiness(businessData.Name));
      navigate(`/analytics?uuid=${businessData.UniqueId}`, { state: { businessData } });
    }, 1000); 
  };

  const handleCardClick = async (e: any, url: string) =>{
    e.preventDefault();
    const filterBusiness: TransformedObject[] = businessProfiles.filter((business:TransformedObject)=>{
         return business.Website.toLowerCase() ===  url.toLowerCase();
    })
    handleExpandBusiness(filterBusiness[0]);
  }

  return (
    <Box className="dashboard" sx={{ paddingTop: 6, minHeight: "100vh" }}>
      <Typography variant="h4">Dashboard</Typography>
      {/* Dashboard Tabs */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "flex-end",
          columnGap: "1.5rem",
          rowGap: "1.5rem",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          className="dashboard-tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#fff", // Sets the indicator color
            },
            "& .MuiTab-root": {
              color: "#000", // Default text color for tabs
            },
            "& .MuiTab-root.Mui-selected": {
              color: "#fff !important", // Enforce white color for active tab text
            },
          }}
        >
          <Tab label="Organic" className="tab" />
          <Tab label="Web" className="tab" />
          <Tab label="Local" className="tab" />
        </Tabs>
        <Box>
          <TextField
            sx={{
              "& .MuiInputBase-root": {
                height: "40px",
                padding: 0, // Remove default padding
                display: "flex",
                alignItems: "center", // Center content vertically
                background: "rgba(255, 255, 255, 0.12)",
                color: "#FFFFFF80",
              },
              "& .MuiFilledInput-root::before": {
                borderBottom: "none", // Remove default bottom border
              },
              "& .MuiFilledInput-root.Mui-focused::after": {
                borderBottom: "2px solid #fff", // Show bottom border only on focus
              },
              "& .MuiInputBase-input": {
                padding: "0 8px", // Adjust padding inside the input
                lineHeight: "1.5", // Adjust line height to keep text centered
                color: "#FFFFFF80", // Default input text color
              },
              "& .MuiInputBase-input:focus": {
                color: "#FFFFFF", // Input text color on focus
              },
              "& .MuiInputAdornment-root": {
                marginRight: "8px",
              },
            }}
            placeholder="Enter Business Name"
            variant="filled"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "#fff" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month} // Make sure this matches one of the timePerios values
              onChange={(e) => {
                setMonth(e.target.value);
              }}
              sx={{
                color: "#6e6b6b",
                height: "40px",
                outline: "none",
                background: "#fff",
                border: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {timePerios.map((timePeriod) => (
                <MenuItem key={timePeriod.id} value={timePeriod.value}>
                  {timePeriod.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Tab Contents */}
      <Loader spinning={loading}>
        <Box className="tab-contents">
          {selectedTab === 0 && (
             <LazyLoad height={screenHeight / 2} offset={screenHeight / 4}>
            <Box className="cardContainer">
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2 }}
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(2, 1fr)",
                    xl: "repeat(3, 1fr)",
                  },
                }}
              >
                {filteredGsc.length > 0 &&
                  filteredGsc.map((item: any, index: number) => {
                    // Parse the JSON strings from mainstats and graph
                    const mainStats = JSON.parse(item.mainstats);
                    const graphData = JSON.parse(item.graph);

                    // Extract sessions and conversions info
                    const clicks = mainStats?.Clicks || {};
                    const impressions = mainStats?.Impressions || {};

                    return (
                      <Grid key={index}>
                        <Card className="card" 
                           onClick={(e) => handleCardClick(e, item.url)} 
                           style={{ cursor: 'pointer' }}
                        >
                          <Box className="cardContent">
                            <Box>
                              <Typography variant="h6">
                                {extractDomainName(item.url)}
                              </Typography>
                              <Typography variant="body1" component="span">
                                {item.url}
                              </Typography>
                            </Box>
                            <Box className="dashboard-logo-placeholder"></Box>
                          </Box>
                          <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                            {graphData?.rows?.length > 0 && (
                              <CustomDashboardAreaCharts
                                key={JSON.stringify(graphData)}
                                chartType="area"
                                categories={graphData.rows.map((point: any) =>
                                  dayjs(point.keys[0]).format("YYYY-MM-DD")
                                )}
                                seriesData={[
                                  {
                                    name: "Clicks",
                                    data: graphData.rows.map((point: any) =>
                                      parseInt(point.clicks, 10)
                                    ),
                                  },
                                  {
                                    name: "Impressions",
                                    data: graphData.rows.map((point: any) =>
                                      parseInt(point.impressions, 10)
                                    ),
                                  },
                                ]}
                                title="Custom Chart Title"
                              />
                            )}
                          </Box>
                          <Box className="cardContent">
                            <Box sx={{ textTransform: "uppercase" }}>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "12px", fontWeight: 500 }}
                              >
                                Clicks
                              </Typography>
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{ fontSize: "12px", fontWeight: 500 }}
                              >
                                Impressions
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "right" }}>
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{ fontSize: "16px" }}
                              >
                                {clicks.Total}
                                <Label
                                  increase={clicks.Trend === "up"}
                                  label={clicks.Diff}
                                  color={
                                    clicks.Trend === "up" ? "success" : "error"
                                  }
                                  size="12px"
                                />
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "16px" }}
                              >
                                {impressions.Total}
                                <Label
                                  increase={impressions.Trend === "up"}
                                  label={impressions.Diff}
                                  color={
                                    impressions.Trend === "up"
                                      ? "success"
                                      : "error"
                                  }
                                  size="12px"
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
            </LazyLoad>
          )}

          {selectedTab === 1 && (
            <LazyLoad height={screenHeight / 2} offset={screenHeight / 4}>
            <Box className="cardContainer">
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2 }}
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(2, 1fr)",
                    xl: "repeat(3, 1fr)",
                  },
                }}
              >
                {filteredGa4.length > 0 &&
                  filteredGa4.map((item: any, index: number) => {
                    // Parse the JSON strings from mainstats and graph
                    const mainStats = JSON.parse(item.mainstats);
                    const graphData = JSON.parse(item.graph);

                    // Extract sessions and conversions info
                    const sessions = mainStats.Sessions;
                    const conversions = mainStats.Conversions;

                    return (
                      <Grid key={index}>
                        <Card className="card"
                         onClick={(e) => handleCardClick(e, item.url)} 
                         style={{ cursor: 'pointer' }}
                        >
                          <Box className="cardContent">
                            <Box>
                              <Typography variant="h6">
                                {extractDomainName(item.url)}
                              </Typography>
                              <Typography variant="body1" component="span">
                                {item.url}
                              </Typography>
                            </Box>
                            <Box className="dashboard-logo-placeholder"></Box>
                          </Box>
                          <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                            {graphData.length > 0 && (
                              <CustomDashboardAreaCharts
                                key={JSON.stringify(graphData)}
                                chartType="area"
                                colors={["#007bff", "#78909C"]}
                                categories={graphData.map((point: any) =>
                                  dayjs(point.curDate).format("YYYY-MM-DD")
                                )}
                                seriesData={[
                                  {
                                    name: "Sessions",
                                    data: graphData.map((point: any) =>
                                      parseInt(point.curData["Sessions"], 10)
                                    ),
                                  },
                                  {
                                    name: "Conversions",
                                    data: graphData.map((point: any) =>
                                      parseInt(point.curData.Conversions, 10)
                                    ),
                                  },
                                ]}
                                title="Custom Chart Title"
                              />
                            )}
                          </Box>
                          <Box className="cardContent">
                            <Box sx={{ textTransform: "uppercase" }}>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "12px", fontWeight: 500 }}
                              >
                                Sessions
                              </Typography>
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{ fontSize: "12px", fontWeight: 500 }}
                              >
                                Conversions
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "right" }}>
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{ fontSize: "16px" }}
                              >
                                {sessions.Total}
                                <Label
                                  increase={sessions.Trend === "up"}
                                  label={sessions.Diff}
                                  color={
                                    sessions.Trend === "up"
                                      ? "success"
                                      : "error"
                                  }
                                  size="12px"
                                />
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "16px" }}
                              >
                                {conversions.Total}
                                <Label
                                  increase={conversions.Trend === "up"}
                                  label={conversions.Diff}
                                  color={
                                    conversions.Trend === "up"
                                      ? "success"
                                      : "error"
                                  }
                                  size="12px"
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
            </LazyLoad>
          )}

          {selectedTab === 2 && (
            <LazyLoad height={screenHeight / 2} offset={screenHeight / 4}>
            <Box className="cardContainer">
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2 }}
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(2, 1fr)",
                    xl: "repeat(3, 1fr)",
                  },
                }}
              >
                {filteredGbp.length > 0 &&
                  filteredGbp.map((item: any, index: number) => {
                    const mainStats = JSON.parse(item.reviews || "{}");
                    const graphData = item.interactions
                      ? JSON.parse(item.interactions)
                      : null;
                    const timeSeriesDatedValues =
                      graphData?.timeSeriesDatedValues
                        ? JSON.parse(graphData.timeSeriesDatedValues)
                        : [];

                    // Generate graph categories
                    const graphCategories =
                      timeSeriesDatedValues.length > 0
                        ? timeSeriesDatedValues.map((point: any) => {
                            const day = point.Date.slice(0, 2);
                            const month = point.Date.slice(2, 4);
                            const year = point.Date.slice(4, 8);
                            return dayjs(`${year}-${month}-${day}`).format(
                              "YYYY-MM-DD"
                            );
                          })
                        : [dayjs().format("YYYY-MM-DD")]; // Placeholder with today's date

                    // Generate graph series data
                    const graphSeriesData =
                      timeSeriesDatedValues.length > 0
                        ? [
                            {
                              name: "Interactions",
                              data: timeSeriesDatedValues.map(
                                (row: any) => row.Value ?? 0
                              ),
                            },
                          ]
                        : [
                            {
                              name: "Interactions",
                              data: [0], // Placeholder value for the graph
                            },
                          ];

                    // Extract reviews and ratings info
                    const reviewspast = mainStats.reviewspast || {};
                    const ratingspast = mainStats.ratingspast || {};

                    return (
                      <Grid key={index}>
                        <Card className="card"
                          onClick={(e) => handleCardClick(e, item.url)} 
                          style={{ cursor: 'pointer' }}
                        >
                          <Box className="cardContent">
                            <Box>
                              <Typography variant="h6">
                                {extractDomainName(item.url)}
                              </Typography>
                              <Typography variant="body1" component="span">
                                {item.url}
                              </Typography>
                            </Box>
                            <Box className="dashboard-logo-placeholder"></Box>
                          </Box>
                          <Box sx={{ marginTop: 1, marginBottom: 1 }}>
                            <CustomDashboardAreaCharts
                              key={JSON.stringify(graphSeriesData)}
                              chartType="area"
                              colors={["#007bff", "#6f42c1"]}
                              categories={graphCategories}
                              seriesData={graphSeriesData}
                              title="Custom Chart Title"
                            />
                          </Box>
                          <Box className="cardContent">
                            <Box sx={{ textTransform: "uppercase" }}>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "12px", fontWeight: 500 }}
                              >
                                Reviews
                              </Typography>
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{ fontSize: "12px", fontWeight: 500 }}
                              >
                                Ratings
                              </Typography>
                            </Box>
                            <Box sx={{ textAlign: "right" }}>
                              <Typography
                                variant="body1"
                                component="span"
                                sx={{ fontSize: "16px" }}
                              >
                                {reviewspast.Total || 0}
                                <Label
                                  increase={reviewspast.Trend === "up"}
                                  label={reviewspast.Diff || "N/A"}
                                  color={
                                    reviewspast.Trend === "up"
                                      ? "success"
                                      : "error"
                                  }
                                  size="12px"
                                />
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: "16px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Rating
                                  name="star-rating"
                                  value={ratingspast.Total || 0}
                                  readOnly
                                  precision={0.1}
                                  icon={
                                    <StarIcon
                                      fontSize="inherit"
                                      sx={{ color: "#FF9800" }}
                                    />
                                  }
                                  emptyIcon={
                                    <StarIcon
                                      fontSize="inherit"
                                      sx={{ color: "#ccc" }}
                                    />
                                  }
                                  size="small"
                                  sx={{ marginRight: "5px" }}
                                />
                                {ratingspast.Total || 0}
                                <Label
                                  increase={ratingspast.Trend === "up"}
                                  label={ratingspast.Diff || "N/A"}
                                  color={
                                    ratingspast.Trend === "up"
                                      ? "success"
                                      : "error"
                                  }
                                  size="12px"
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
            </LazyLoad>
          )}
        </Box>
      </Loader>
    </Box>
  );
};

export default AdminDashboard;
