/* eslint-disable react/jsx-pascal-case */

import AdminDashboard from "../../Pages/dashboard/AdminDashboard";
import AdminErrorPage from "../../Pages/errorPage/AdminErrorPage";
import AdminHomepage from "../../Pages/homepage/AdminHomepage";
import BusinessCampaign from "../../Pages/businesscampaign/BusinessCampaign";
import BusinessDashboard from "../../Pages/businessDashboard/BusinessDashboard";
import BusinessLogin from "../../Pages/businessmenu/login/BusinessLogin";
import BusinessProfile from "../../Pages/businessProfile/BusinessProfile";
import BusinessReviews from "../../Pages/businessReviews/BusinessReviews";
import CreateBusinessProfile from "../../Pages/businessmenu/createbusiness/CreateBusinessProfile";
import CreateUser from "../../Pages/usersmenu/create/CreateUser";
import EditBusinessProfile from "../../Pages/businessProfile/EditProfile";
import FileUpload from "../webtools/FileUploader";
import GBPReviewList from "../../Pages/gbp/GBPReviewList";
import GSCLandingPage from "../../Pages/gsc/gscLanding/GSCLandingPage";
import GSCSpecificBusinessProfile from "../../Pages/gsc/gscSpecificBusinessProfile/GSCSpecificBusinessProfile";
import GoogleAnalaytics from "../../Pages/ga4/GoogleAnalaytics";
import GoogleAnalyticsSpecificBusinessProfile from "../../Pages/ga4/GoogleAnalyticsSpecificBusinessProfile";
import KeywordClustering from "../KeywordClustering/KeywordClustering";
import LoginPagePresentation from "../../Pages/login/LoginPagePresentation";
import MassicChatView from "../chatview/MassicChatView";
import { Navigate } from "react-router-dom";
import NewBusinessProfile from "../../Pages/newBusinessProfile/NewBusinessProfile";
import PrivacyPolicy from "../../Pages/privacy/PrivacyPolicy";
import ProtectedRoute from "../ProtectedRoutes/ProtectedRoutes";
import ReviewEmailEditScreen from "../../Pages/businessReviews/ReviewEmailEditScreen";
import ReviewSMSEditScreen from "../../Pages/businessReviews/ReviewSMSEditScreen";
import ReviewsAddCampaign from "../../Pages/businessReviews/AddCampaign";
import SMS_EmailCampaign from "../../Pages/businessReviews/SMS_EmailCampaign";
import SettingsBusinessProfile from "../../Pages/businessmenu/settings/SettingsBusinessProfile";
import SettingsUser from "../../Pages/usersmenu/settings/SettingsUser";
import SignInGoogle from "../../Pages/businessmenu/settings/SignInGoogle";
import TermsConditions from "../../Pages/terms/TermsConditions";
import TopicStartegyBuilder from "../../Pages/topicStartegyBuilder/TopicStartegyBuilder";
import UnProtectedRoutes from "../UnProtectedRoutes/UnProtectedRoutes";
import UserErrorPage from "../../Pages/errorPage/UserErrorPage";
import UserHomepage from "../../Pages/homepage/UserHompage";
import ViewBusinessProfiles from "../../Pages/businessmenu/view/ViewBusinessProfiles";
import ViewUser from "../../Pages/usersmenu/view/ViewUsers";
import WebScraping from "../WebScraping/WebScrapingMain";

//import BusinessLandingPage from "../../Pages/businessmenu/businessLanding/BusinessLandingPage";

interface IRouteComponents {
  path: string;
  element: JSX.Element;
  errorElement?: JSX.Element;
  children?: any;
}

const routePaths = Object.freeze({
  settings: "/",
  home: "/",
  login: "/",
  privacy: "/privacy",
  terms: "/terms",
  userDashboard: "/userdashboard",
  dashboard: "/home",
  singup: "/user-signup",
  errorpage: "/error-page",
  // ==================== Business Menu ====================
  businesscreate: "/create-business",
  businessview: "/view-business",
  businesssettings: "/settings",
  businesslogin: "/business-login",
  signin: "/signin-google",
  gsclogin: "/gsc-login",
  ga4login: "/google-analytics",

  // ==================== Business Profile ====================

  businessprofile: "/profile",
  newBusinessprofile: "/new-profile",
  editbusinessprofile: "/edit-profile/:businessId",
  businessdashboard: "/analytics",
  businessreviews: "/reviews",
  businessreviewsAddCampaign: "/new-campaign",
  businessreviewsAddSMS_EmailCampaign: "/new-sms-email-campaign",
  businessreviewsEditEmailScreen: "/edit-email-screen",
  businessreviewsEditSMSScreen: "/edit-activity-screen",
  businesscampaign: "/campaign",

  // ==================== Users Menu ====================

  usercreate: "/create-user",
  userview: "/view-users",
  usersettings: "/settings-user",

  // ==================== Tools ==========================
  gscSpecificBusinessProfile: "/gsc/:profileName",
  ga4SpecificBusinessProfile: "/ga4/:profileName",
  gbpReviewList: "/gbp/review-list",
  chatbot: "/ai-chatbot",
  webscraping: "/web-scraping",
  keywordClustering: "/keyword-clustering",
  webtools: "/web-tools",
  topicStartegyBuilder: "/topic-startegy",
});

//for Massic Chat
const title = "Chat with Pesca Beach";
const height = 16;
const timeout = 5;
const apiUrl = "/ask";
const apiMethod = "GET";
const fileSupported = ".xlsx,.xls,.csv,.pdf";

export const AllRoutes: Array<IRouteComponents> = [
  {
    path: routePaths.login,
    element: (
      <UnProtectedRoutes>
        <LoginPagePresentation />
      </UnProtectedRoutes>
    ),
  },
  {
    path: "*",
    element: (
      <UnProtectedRoutes>
        <Navigate to="/" replace />
      </UnProtectedRoutes>
    ),
  },
  {
    path: routePaths.privacy,
    element: (
      <UnProtectedRoutes>
        <PrivacyPolicy />
      </UnProtectedRoutes>
    ),
  },
  {
    path: routePaths.terms,
    element: (
      <UnProtectedRoutes>
        <TermsConditions />
      </UnProtectedRoutes>
    ),
  },
  {
    path: routePaths.singup,
    element: (
      <UnProtectedRoutes>
        <CreateUser update={false} editable={false} />
      </UnProtectedRoutes>
    ),
  },
  {
    path: routePaths.errorpage,
    element: (
      <UnProtectedRoutes>
        <UserErrorPage />
      </UnProtectedRoutes>
    ),
  },
  {
    path: routePaths.home,
    element: (
      <ProtectedRoute>
        <AdminHomepage />
      </ProtectedRoute>
    ),
    errorElement: <AdminErrorPage />,
    children: [
      {
        index: true,
        path: routePaths.dashboard,
        element: (
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        ),
      },
      //==================== Business Menu ====================
      {
        path: routePaths.businesscreate,
        element: (
          <ProtectedRoute>
            <CreateBusinessProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businessview,
        element: (
          <ProtectedRoute>
            <ViewBusinessProfiles />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businesssettings,
        element: (
          <ProtectedRoute>
            <SettingsBusinessProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.settings,
        element: (
          <ProtectedRoute>
            <SettingsBusinessProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businesslogin,
        element: (
          <ProtectedRoute>
            <BusinessLogin />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.signin,
        element: (
          <ProtectedRoute>
            <SignInGoogle />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.gsclogin,
        element: (
          <ProtectedRoute>
            <GSCLandingPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.gbpReviewList,
        element: (
          <ProtectedRoute>
            <GBPReviewList />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.ga4login,
        element: (
          <ProtectedRoute>
            <GoogleAnalaytics />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.ga4SpecificBusinessProfile,
        element: (
          <ProtectedRoute>
            <GoogleAnalyticsSpecificBusinessProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.chatbot,
        element: (
          <ProtectedRoute>
            <MassicChatView
              defaultTitle={title}
              height={height}
              timeout={timeout}
              apiUrl={apiUrl}
              apiMethod={apiMethod}
              fileSupported={fileSupported}
            />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.webscraping,
        element: (
          <ProtectedRoute>
            <WebScraping />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.topicStartegyBuilder,
        element: (
          <ProtectedRoute>
            <TopicStartegyBuilder />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.webtools,
        element: (
          <ProtectedRoute>
            <FileUpload />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.keywordClustering,
        element: (
          <ProtectedRoute>
            <KeywordClustering />
          </ProtectedRoute>
        ),
      },
      //==================== Business Menu ====================
      {
        path: routePaths.businessdashboard,
        element: (
          <ProtectedRoute>
            <BusinessDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businessreviews,
        element: (
          <ProtectedRoute>
            <BusinessReviews />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businessreviewsAddCampaign,
        element: (
          <ProtectedRoute>
            <ReviewsAddCampaign />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businessreviewsAddSMS_EmailCampaign,
        element: (
          <ProtectedRoute>
            <SMS_EmailCampaign />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businessreviewsEditEmailScreen,
        element: (
          <ProtectedRoute>
            <ReviewEmailEditScreen />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businessreviewsEditSMSScreen,
        element: (
          <ProtectedRoute>
            <ReviewSMSEditScreen />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businesscampaign,
        element: (
          <ProtectedRoute>
            <BusinessCampaign />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.businessprofile,
        element: (
          <ProtectedRoute>
            <BusinessProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.newBusinessprofile,
        element: (
          <ProtectedRoute>
            <NewBusinessProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.editbusinessprofile,
        element: (
          <ProtectedRoute>
            <EditBusinessProfile />
          </ProtectedRoute>
        ),
      },
      //==================== Users Menu ====================
      {
        path: routePaths.usercreate,
        element: (
          <ProtectedRoute>
            <CreateUser update={false} editable={false} />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.userview,
        element: (
          <ProtectedRoute>
            <ViewUser />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.usersettings,
        element: (
          <ProtectedRoute>
            <SettingsUser />
          </ProtectedRoute>
        ),
      },
      {
        path: routePaths.gscSpecificBusinessProfile,
        element: (
          <ProtectedRoute>
            <GSCSpecificBusinessProfile />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: routePaths.userDashboard,
    element: (
      <ProtectedRoute>
        <UserHomepage />
      </ProtectedRoute>
    ),
    errorElement: <UserErrorPage />,
    children: [],
  },
];
